import * as React from "react";

function TrendingIcon() {
  return (
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8c6c993d543b6c78d0511c245fc94a852bc550b95cfe04b3c66c88d793a230c?apiKey=a938513dc279413eaac5ce0f8c637f66&"
      className="trending-icon"
      alt="Trending icon"
    />
  );
}

function TrendingLabel() {
  return <div className="trending-label">Trending</div>;
}

function TrendingSelect({ options, value, onChange }) {
  return (
    <select
      className="trending-select"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export default function Dropdown() {
  const options = ["Option 1", "Option 2", "Option 3"];
  const [selectedOption, setSelectedOption] = React.useState(options[0]);

  const handleChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <>
      <div className="trending-container">
        <div className="trending-badge">
          <TrendingLabel />
          <div className="trending-icon-wrapper">
            <TrendingIcon />
          </div>
        </div>
        <TrendingSelect
          options={options}
          value={selectedOption}
          onChange={handleChange}
        />
      </div>
      <style jsx>{`
        .trending-container {
          align-self: stretch;
          background-color: var(--WHITE, #fff);
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          max-width: 115px;
          position: relative;
        }

        .trending-badge {
          background-color: var(--WHITE, #fff);
          border: 1px solid rgba(111, 113, 113, 1);
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 6px 8px 6px 7px;
          width: 100%;
        }

        .trending-label {
          color: var(--GRAY44, #6f7171);
          font: 400 12px/167% Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          font-feature-settings: "clig" off, "liga" off;
          justify-content: center;
          letter-spacing: 0.5px;
          white-space: nowrap;
        }

        .trending-icon-wrapper {
          align-items: center;
          border-radius: var(--ID, 0px) 4px 4px var(--ID, 0px);
          display: flex;
          justify-content: center;
          margin: auto 0;
          padding: 6px 8px;
        }

        .trending-icon {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 20px;
        }

        .trending-select {
          background-color: var(--WHITE, #fff);
          border: 1px solid rgba(111, 113, 113, 1);
          border-radius: 4px;
          color: var(--GRAY44, #6f7171);
          font: 400 12px/167% Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          margin-top: 4px;
          padding: 4px 8px;
          width: 100%;
        }
      `}</style>
    </>
  );
}