// import React from 'react'

// function Space() {
//   return (
//     <div>
//       <h1>space</h1>
//     </div>
//   )
// }

// export default Space
import * as React from "react";

function Space1({ className }) {
  return <div className={`space ${className}`} />;
}

function Space() {
  return (
    <>
      <main>
        <Space1 className="space-small" />
        <Space1 className="space-medium" />
        <Space1 className="space-large" />
      </main>

      <style jsx>{`
        .space {
          background-color: transparent;
        }
        .space-small {
          width: 10px;
          height: 10px;
        }
        .space-medium {
          width: 20px; 
          height: 20px;
        }
        .space-large {
          width: 30px;
          height: 30px;  
        }
      `}</style>
    </>
  );
}

export default Space;
