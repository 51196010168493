import * as React from "react";
import styled from "styled-components";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  width: 40px;
  height: 40px;
`;

const LogoText = styled.span`
  font-size: 24px;
  margin-left: 10px;
  font-weight: bold;
`;

export default function Logo() {
  return (
    <LogoWrapper>
      <LogoImage src="/logo.png" alt="Company Logo" />
      <LogoText>Company Name</LogoText>
    </LogoWrapper>
  );
}
