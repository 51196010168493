
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/Login';
import Chat from './components/Chat';
import ChatHistory from './components/ChatHistory';
import SignUp from './components/SignUp';
import Sample from './components/Sample';
import Dashboard from './components/Dashboard';
import Page2 from './components/Page2';
import Page4 from './components/Page4';
import Dropdown from './components/Dropdown';






const App = () => {
  return (
    <Router>
      <Routes>
   
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/chathistory" element={<ChatHistory />} />
        <Route path="/sample" element={<Sample />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/page2" element={<Page2 />} />
        <Route path="/page4" element={<Page4 />} />
        <Route path="/dropdown" element={<Dropdown />} />
      
      
   
     
       
      </Routes>
    </Router>
  );
};

export default App;


