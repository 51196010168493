import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate} from "react-router-dom"; 
import loaderImage from "../assets/images/loader1.gif";
import { ToastContainer, toast } from 'react-toastify'; // Import the entire module
import 'react-toastify/dist/ReactToastify.css';
import './Login.css'
const data = [
  {
    id: 1,
    title: "Create a highly itinerary with multiple options.",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/e92a31730a1c1535291911a71f073fef3ecbcd981588f6c1c33f725a6439c10f?apiKey=aafae26bdbcc40e0829623d48788b669&",
  },
  {
    id: 2,
    title: "Provide current entertainment trends",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/a18957c3ed1c3958478c009747dde5213f17c09efdf3165eed0a8644aa2ba964?apiKey=aafae26bdbcc40e0829623d48788b669&",
  },
];

function SignUp() {
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate(); // Initialize useHistory
  const handlelogin=()=>{
    navigate('/')
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch("https://rag-bio.radiant.digital/api/signup", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            full_name: username,
            email: email,
            password: password
        }),
      });

      if (response.ok) {
       
    
        console.log("signup  successful");
        setLoading(false);
        navigate("/");
      } else {
        setLoading(false);
        console.error("signup failed");
        toast.error('SignUp failed. Username or Email Already existed.');
      }
    } catch (error) {
      setLoading(false);
      console.error("Error occurred during login:", error);
      toast.error('SignUp failed. Username or Email Already existed.');
    }
  };
  return (
    <PageWrapper>
      <ContentWrapper>
        <LoginSection>
          <LoginFormWrapper>
          <ToastContainer />
          {loading && (
            <div className="loader-overlay">
                
                    <img className="imageloader" src={loaderImage} alt="Loader" />
                
            </div>
            )}
            <LoginFormContent>
              <Logo src="https://cdn.builder.io/api/v1/image/assets/TEMP/798ca25248c1a3fa16912862dbd3fbff5e7699df8e112cd05a7d345819964ccb?apiKey=aafae26bdbcc40e0829623d48788b669&" alt="RAG logo" />
              <LoginFormHeader>
                <LoginTitle>Welcome to RAG</LoginTitle>
                <LoginSubtitle>Please enter your details.</LoginSubtitle>
              </LoginFormHeader>
              <LoginForm>
              <LoginFormGroup>
              <LoginFormLabel htmlFor="username">UserName</LoginFormLabel>
              <LoginFormInput
              type="text"
              id="text"
              placeholder="Enter your UserName"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            </LoginFormGroup>
                <LoginFormGroup>
                  <LoginFormLabel htmlFor="email">Email</LoginFormLabel>
                  <LoginFormInput
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                </LoginFormGroup>
                <LoginFormGroup>
                  <LoginFormLabel htmlFor="password">Password</LoginFormLabel>
                  <LoginFormInput
                  type="password"
                  id="password"
                  placeholder="••••••••"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                </LoginFormGroup>
                <LoginFormOptions>
                  <RememberMe>
                    <Checkbox />
                    <RememberMeLabel>Remember for 30 days</RememberMeLabel>
                  </RememberMe>
                  <ForgotPassword>Forgot password</ForgotPassword>
                </LoginFormOptions>
                <LoginButton type="submit"  onClick={handleSubmit}>Sign Up</LoginButton>
              </LoginForm>
              
              <SignUpPrompt>
                <SignUpText>Already have an account?</SignUpText>
                <SignUpLink  onClick={handlelogin} style={{"cursor":"pointer"}}>Login</SignUpLink>
              </SignUpPrompt>
            </LoginFormContent>
          </LoginFormWrapper>
        </LoginSection>
        <FeaturesSection>
          <FeaturesSectionContent>
            <FeaturesTitle>Your everyday AI companion</FeaturesTitle>
            <FeaturesGrid>
              {data.map((feature) => (
                <FeatureItem key={feature.id}>
                  <FeatureImage src={feature.image} alt="Feature icon" />
                  <FeatureTitle>{feature.title}</FeatureTitle>
                </FeatureItem>
              ))}
            </FeaturesGrid>
            <SocialIcons>
              <SocialIcon>
                <SocialImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/979ce396f64e5c94b693d529423cce09c9eef583d0a0550e3f8bbb19be688e78?apiKey=aafae26bdbcc40e0829623d48788b669&" alt="Social icon" />
              </SocialIcon>
              <SocialIcon>
                <SocialImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/ba4c0f7e9a36161b26dafab082e46a59554410a51fd0926b968a4272f20cb367?apiKey=aafae26bdbcc40e0829623d48788b669&" alt="Social icon" />
              </SocialIcon>
            </SocialIcons>
          </FeaturesSectionContent>
        </FeaturesSection>
      </ContentWrapper>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--Base-White, #fff);
  flex-grow: 1;
  width: 100%;
  min-height: 100vh;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin-top: 40px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const LoginSection = styled.section`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 54%;
  margin-left: 0px;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const LoginFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-self: stretch;
  flex-grow: 1;
  flex-direction: column;


  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const LoginFormContent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;

  padding: 0 32px;
  width: 360px;
  max-width: 100%;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Logo = styled.img`
  aspect-ratio: 6.67;
  object-fit: contain;
  object-position: center;
  width: 148px;
  max-width: 100%;
`;

const LoginFormHeader = styled.header`
  display: flex;
  flex-direction: column;
 


  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const LoginTitle = styled.h1`
  color: var(--Gray-900, var(--Gray-900, #101828));
  font: 600 30px/127% Inter, sans-serif;
`;

const LoginSubtitle = styled.p`
  color: var(--Gray-600, var(--Gray-600, #475467));
  margin-top: 12px;
  font: 400 16px/150% Inter, sans-serif;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const LoginFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const LoginFormLabel = styled.label`
  color: var(--Gray-700, var(--Gray-700, #344054));
  font: 500 14px/143% Inter, sans-serif;
  margin-bottom: 6px;
`;

const LoginFormInput = styled.input`
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid rgba(208, 213, 221, 1);
  background-color: var(--Base-White, #fff);
  font-size: 16px;
  color: var(--Gray-500, var(--Gray-500, #667085));
  font-weight: 400;
  line-height: 150%;
  padding: 10px 14px;
`;

const LoginFormOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

const RememberMe = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Checkbox = styled.div`
  display: flex;
  justify-content: center;
  align-self: start;
  flex-direction: column;
  padding: 2px 0 1px;
  border-radius: 4px;
  border: 1px solid rgba(208, 213, 221, 1);
  background-color: var(--Base-White, #fff);
  width: 16px;
  height: 16px;
`;

const RememberMeLabel = styled.label`
  color: var(--Gray-700, var(--Gray-700, #344054));
  font: 500 14px/143% Inter, sans-serif;
`;

const ForgotPassword = styled.a`
color: var(--secondary-color);
  
  font: 600 14px/143% Inter, sans-serif;
  text-decoration: none;
`;

const LoginButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  font-size: 16px;
  color: var(--Base-White, var(--Base-White, #fff));
  font-weight: 600;
  line-height: 150%;
  font-family: Inter, sans-serif;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid rgba(127, 86, 217, 1);
  background-color: var(--secondary-color);

  padding: 10px 18px;
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;






const SignUpPrompt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 23px;
  gap: 4px;
  font-size: 14px;
  line-height: 143%;
  padding: 0 75px;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const SignUpText = styled.p`
  color: var(--Gray-600, var(--Gray-600, #475467));
  font-family: Inter, sans-serif;
  font-weight: 400;
`;

const SignUpLink = styled.a`
  font-family: Inter, sans-serif;
  color: var(--secondary-color);
  
  font-weight: 600;
  text-decoration: none;
  
`;

const FeaturesSection = styled.section`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 46%;
  margin-left: 20px;

  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
    margin-top: 60px;
  }
`;

const FeaturesSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  border-radius: 64px 0px 0px 64px;
  background-color: #e5eaf1;
  flex-grow: 1;
  width: 100%;
  padding: 80px 0 80px 23px;
   max-height: 74vh;

  @media (max-width: 991px) {
    max-width: 100%;
    border-radius: 0;
    padding: 60px 20px;
  }
`;

const FeaturesTitle = styled.h2`
  color: #000;
  margin-top: 182px;
  font: 600 18px/150% Inter, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 0;
  }
`;

const FeaturesGrid = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 30px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 30px;
    max-width: 100%;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 18px;
  color: #101828;
  font-weight: 500;
  line-height: 27px;
`;

const FeatureImage = styled.img`
 
  object-fit: contain;
  object-position: center;
  width: 100%;
`;

const FeatureTitle = styled.h3`
  font-family: Inter, sans-serif;
  margin-top: 24px;
`;

const SocialIcons = styled.div`
  display: flex;
  align-self: flex-start;
 
  gap: 20px;
`;

const SocialIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  backdrop-filter: blur(4px);
  border: 1px solid rgba(102, 112, 133, 1);
  padding: 16px;
`;

const SocialImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
`;

export default SignUp;