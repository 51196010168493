import * as React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import LeftNavComponent from "./Sidebar";
import Base from "./Base";



// import Base from "@/components/Base";
import Space from "./Space";
// import Space from "@/components/Space";
import Logo from "./Logo";
// import { Logo } from "@/components/Logo";
import Hamburger from "./Hamburger";
// import { Hamburger } from "@/components/Hamburger";

export default function Dashboard() {
    const navigate = useNavigate();
    const handleclick=()=>{
        navigate("/Page2");
    }
  return (
    <Div>
      <Div2>
        <Div3>
          <Div4>
            <Div5>
              <Div6>
                <Base>
                  <Logo />
                  <Hamburger />
                </Base>
              </Div6>
              <Div7>
                <Base1 />
                <Base />
                <Base3 />
                <Base4 />
                <Img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a7a3f19c265e94cd241d736a95ff53456d8e5934aba484f5c178513caeda500f?apiKey=a938513dc279413eaac5ce0f8c637f66&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a3f19c265e94cd241d736a95ff53456d8e5934aba484f5c178513caeda500f?apiKey=a938513dc279413eaac5ce0f8c637f66&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a3f19c265e94cd241d736a95ff53456d8e5934aba484f5c178513caeda500f?apiKey=a938513dc279413eaac5ce0f8c637f66&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a3f19c265e94cd241d736a95ff53456d8e5934aba484f5c178513caeda500f?apiKey=a938513dc279413eaac5ce0f8c637f66&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a3f19c265e94cd241d736a95ff53456d8e5934aba484f5c178513caeda500f?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a3f19c265e94cd241d736a95ff53456d8e5934aba484f5c178513caeda500f?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a3f19c265e94cd241d736a95ff53456d8e5934aba484f5c178513caeda500f?apiKey=a938513dc279413eaac5ce0f8c637f66&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a7a3f19c265e94cd241d736a95ff53456d8e5934aba484f5c178513caeda500f?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                />
                <Base5 />
              </Div7>
            </Div5>
          </Div4>
          <Div8>
            <Div9 />
            <Div10>
              <Div11>
                <Base />
              </Div11>
            </Div10>
          </Div8>
        </Div3>
        <Div12>
          <Div13>
            <Img2
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/07143eb4925f8e1c6ea2ea2e2e8afa021d8d28337ea58078e75b9d4dd5d24250?apiKey=a938513dc279413eaac5ce0f8c637f66&"
            />
            <Div14>
              <Img3
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/8a7ee0eb2348aef320faf934e98877b7c468fa5cd75f5abedcd2a4182c9979d6?apiKey=a938513dc279413eaac5ce0f8c637f66&"
              />
              <Div15>121 - Ballimore - NYE</Div15>
            </Div14>
          </Div13>
          <Div16>
            <Img4
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/5a99c9a6b45a545632ef04775b24bbff1250881aada137ced9541bec5c567d45?apiKey=a938513dc279413eaac5ce0f8c637f66&"
            />
            <Div17>4G</Div17>
            <Img5
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/cee4e6c5b35acbf40007e5a5eaa48ebe47bcddf78326d523187471bebac4c549?apiKey=a938513dc279413eaac5ce0f8c637f66&"
            />
            <Div18>Mobility</Div18>
          </Div16>
        </Div12>
        <Div19>
      <LeftNavComponent/>
          <Div39>
            <Div40>
              <Div41>
                <Column>
                  <Div42>
                    <Div43>
                      <Div44>Impacted KPI’s</Div44>
                      <Div45>
                        <Div46>
                          <Base14 />
                        </Div46>
                        <Div47 />
                        <Div48>
                          <Div49>D</Div49>
                          <Div50>H</Div50>
                        </Div48>
                        <Div51>
                          <Base15 />
                          <Img7
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9b4a015ba0505b0878386e0598d359b04e223643d89ec77b2218aa5a6ddc760d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b4a015ba0505b0878386e0598d359b04e223643d89ec77b2218aa5a6ddc760d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b4a015ba0505b0878386e0598d359b04e223643d89ec77b2218aa5a6ddc760d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b4a015ba0505b0878386e0598d359b04e223643d89ec77b2218aa5a6ddc760d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b4a015ba0505b0878386e0598d359b04e223643d89ec77b2218aa5a6ddc760d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b4a015ba0505b0878386e0598d359b04e223643d89ec77b2218aa5a6ddc760d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b4a015ba0505b0878386e0598d359b04e223643d89ec77b2218aa5a6ddc760d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b4a015ba0505b0878386e0598d359b04e223643d89ec77b2218aa5a6ddc760d?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                          />
                        </Div51>
                      </Div45>
                    </Div43>
                    <Div52>
                      <Div53>
                        <Div54>
                          <Div55>
                            <Div56 />
                          </Div55>
                          <Div57 />
                          <Div58>
                            <Div59 />
                          </Div58>
                        </Div54>
                        <Div60>
                          <Div61>12 AM</Div61>
                          <Div62>2 AM</Div62>
                        </Div60>
                      </Div53>
                      <Div63>4 AM</Div63>
                      <Div64>6 AM</Div64>
                      <Div65>8 AM</Div65>
                      <Div66>10 AM</Div66>
                      <Div67>12 PM</Div67>
                      <Div68>2 PM</Div68>
                      <Div69>4 PM</Div69>
                      <Div70>6 PM</Div70>
                      <Div71>8 PM</Div71>
                      <Div72>10 PM</Div72>
                      <Div73>12 AM</Div73>
                    </Div52>
                    <Div74>
                      <Div75>
                        <Div76>
                          <Div77>RRC Setup</Div77>
                          <Img8
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a9e3f0d08030462aa17769fc07644a0424b630759fe95f43e9d137986173265?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                          />
                          <Div78>
                            <Div79>12AM</Div79>
                            <Div80>1AM</Div80>
                            <Div81>2AM</Div81>
                          </Div78>
                          <Div82>83</Div82>
                          <Div83>Avg Drops&gt; .5% : 67</Div83>
                        </Div76>
                      </Div75>
                      <Div84 style={{cursor:"pointer"}}onClick={handleclick}>
                        <Div85>
                          <Div86>SIP DC%</Div86>
                          <Img9
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/72a3cd262e48ae44205089d785684584729810845def4362fa6c5077a99aca6c?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                          />
                          <Div87>
                            <Div88>12AM</Div88>
                            <Div89>1AM</Div89>
                            <Div90>2AM</Div90>
                          </Div87>
                          <Div91>94</Div91>
                          <Div92>Avg Drops&gt; .5% : 89</Div92>
                        </Div85>
                      </Div84>
                      <Div93>
                        <Div94>
                          <Div95>Bearer Drop%</Div95>
                          <Img10
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3fd6e0250c745d534f26e3f2d1b162514285485287418bfa22e97d9b423793c1?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                          />
                          <Div96>
                            <Div97>12AM</Div97>
                            <Div98>1AM</Div98>
                            <Div99>2AM</Div99>
                          </Div96>
                          <Div100>88</Div100>
                          <Div101>Avg Drops&gt; .5% : 43 </Div101>
                        </Div94>
                      </Div93>
                      <Div102>
                        <Div103>
                          <Div104>UPTP Mbps</Div104>
                          <Img11
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/56a6f121e643c6b5344c1b74bef3bd666d0682a8cd9b1f05113ce22434d4dbbc?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                          />
                          <Div105>
                            <Div106>12AM</Div106>
                            <Div107>1AM</Div107>
                            <Div108>2AM</Div108>
                          </Div105>
                          <Div109>81</Div109>
                          <Div110>Avg Drops&gt; .5% : 45</Div110>
                        </Div103>
                      </Div102>
                      <Div111>
                        <Div112>
                          <Div113>ENDC Support</Div113>
                          <Img12
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d33845d37b48a7c07a05d0bde25ed2042958117da36324fb010707ce9d226b64?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                          />
                          <Div114>
                            <Div115>12AM</Div115>
                            <Div116>1AM</Div116>
                            <Div117>2AM</Div117>
                          </Div114>
                          <Div118>92</Div118>
                          <Div119>Avg Drops&gt; .5% : 20</Div119>
                        </Div112>
                      </Div111>
                      <Img13
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f7c96ad32482abd5713cfd05e2eb785c7a8191aa758d2fb5c6acdc7131b886f1?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                      />
                    </Div74>
                  </Div42>
                </Column>
                <Column2>
                  <Div120>
                    <Div121>
                      <Div122>Optimise</Div122>
                      <Base16 />
                    </Div121>
                    <Div123>
                      <Div124>5 (Completed)</Div124>
                      <Div125>
                        <Img14
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/3c9897d75759eb9ed13cea090e526feafa4f7e1a41b8d161b10a4673531c3400?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                        />
                        <Div126>
                          <Img15
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/bbbc8e4eda47d3ad60925c4ef17113032e311ca2d8b1ce26e756184e0de540ab?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                          />
                          <Div127>
                            5 (
                        
                            <span style={{color:"rgba(13,154,255,1)"}}>
                              In Progress
                            </span>
                            )
                          </Div127>
                        </Div126>
                      </Div125>
                    </Div123>
                    <Div128>
                      <Div129>
                        <Div130>
                          <Div131 />
                          <Div132>Failed </Div132>
                        </Div130>
                        <Div133>
                          <Div134 />
                          <Div135>In progress</Div135>
                        </Div133>
                        <Div136>
                          <Div137 />
                          <Div138>Completed</Div138>
                        </Div136>
                        <Div139>
                          <Div140 />
                          <Div141>Cancelled</Div141>
                        </Div139>
                      </Div129>
                    </Div128>
                    <Div142>
                      <Div143>Links</Div143>
                      <Div144 />
                    </Div142>
                    <Div145>
                      <Div146>
                        <Div147>
                          <Div148>
                            <Div149>Create Algorithm</Div149>
                            <Img16
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/1bd6fb2374373f9e76c3d715e5154f3ef474e417b5514c9729cc9a7fe5ca168a?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                            />
                          </Div148>
                        </Div147>
                        <Div150>
                          <Div151>
                            <Div152>Algorithms</Div152>
                            <Img17
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/247cbe6d819d68cdc4ec28fb5bd8999ba2f02186ef2c1d64e79284b23551ebd6?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                            />
                          </Div151>
                        </Div150>
                        <Div153>
                          <Div154>
                            <Div155>Scheduled Activation</Div155>
                            <Img18
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b053e1c6693c31e8f605f106e1562306c3d826d868c6c8b647f73d9fc1776a18?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                            />
                          </Div154>
                        </Div153>
                        <Div156>
                          <Div157>
                            <Div158>Parameters</Div158>
                            <Div159>
                              <Img19
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/287a037fb15992a1c5e726adfdefdb31d9ffe7e496854baf80a2a9fee11c2b4d?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                              />
                            </Div159>
                          </Div157>
                        </Div156>
                      </Div146>
                      <Div160>
                        <Div161>
                          <Div162>
                            <Div163>Create Algorithm Rule</Div163>
                            <Img20
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/893cf4aa93d4853a56da80c29e256f99c505702706504d3584f3a5ce5f4779a7?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                            />
                          </Div162>
                        </Div161>
                        <Div164>
                          <Div165>
                            <Div166>Algorithms Rule</Div166>
                            <Img21
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/050cf5981dbc36a48fec926a56606a0e4f198d11d543c4837e52c163190a9d51?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                            />
                          </Div165>
                        </Div164>
                        <Div167>
                          <Div168>
                            <Div169>Conditions</Div169>
                            <Img22
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca23a17b6ff1fa753beb5e022d2fcdbca3ac566d6d3e8040fe0acead5234f3d6?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                            />
                          </Div168>
                        </Div167>
                      </Div160>
                    </Div145>
                  </Div120>
                </Column2>
              </Div41>
            </Div40>
            <Div170>
              <Img23
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/5b80c3baf36b3292cd14467c7846d0f77511f46998774c90677105ba466c5721?apiKey=a938513dc279413eaac5ce0f8c637f66&"
              />
            </Div170>
            <Div171>
              <Div172>
                <div>
                  <Div173>
                    <Div174>
                      <Div175>
                        <Div176>
                          <Div177>CLICK</Div177>
                          <Div178>
                            <Div179>
                              <Div180>Broken Trends by Sites</Div180>
                              <Img24
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/551f5a28af21ce44651ed20b9913907acbbfe6ecb2889eb7889ca2918d30ee68?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                              />
                            </Div179>
                            <Div181>Broken Trends by Events</Div181>
                          </Div178>
                        </Div176>
                        <Div182>
                          <Div183>
                            <Div184>Low Throughputs</Div184>
                            <Img25
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/bdcbe2ff80ed5464e1ef7e8805526ef68b616c36498b7700c949fbd84fc13e13?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                            />
                          </Div183>
                          <Div185>
                            <Div186>Last 24 hrs</Div186>
                            <Img26
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/844dde3de5cad5089e173731adc53b401460aeb002fa0ea7450cbbcbad87e8e1?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                            />
                          </Div185>
                        </Div182>
                      </Div175>
                      <Div187>
                        <Div188>
                          <Column4>
                            <Div189>
                              <Div190>
                                <Div191>
                                  <Div192>
                                    <Base17 />
                                  </Div192>
                                  <Div193>
                                    <Base18 />
                                  </Div193>
                                  <Div194>
                                    <Base19 />
                                  </Div194>
                                  <Div195>
                                    <Base20 />
                                  </Div195>
                                </Div191>
                              </Div190>
                              <Div196>
                                <Div197>Sites</Div197>
                                <Div198>Last Broken</Div198>
                                <Div199>Impacted Sector/Carriers</Div199>
                                <Div200>Alerts</Div200>
                              </Div196>
                              <Div201 />
                              <Div202>
                                <Div203>
                                  <Column5>
                                    <Div204>
                                      <Div205>
                                        <Space1 />
                                        <Div206>447678_Indian_ Lake</Div206>
                                      </Div205>
                                      <Div207>
                                        <Space />
                                        <Div208>147418_Lifesavers</Div208>
                                      </Div207>
                                      <Div209>
                                        <Space />
                                        <Div210>447678_Indian_ Lake</Div210>
                                      </Div209>
                                      <Div211>
                                        <Space />
                                        <Div212>147418_Lifesavers</Div212>
                                      </Div211>
                                      <Div213>
                                        <Space />
                                        <Div214>478823</Div214>
                                      </Div213>
                                    </Div204>
                                  </Column5>
                                  <Column6>
                                    <Div215>
                                      <Div216>Yesterday , 2 P.M</Div216>
                                      <Div217>Yesterday , 2 P.M</Div217>
                                      <Div218>Yesterday , 2 P.M</Div218>
                                      <Div219>Yesterday , 2 P.M</Div219>
                                      <Div220>Yesterday , 2 P.M</Div220>
                                    </Div215>
                                  </Column6>
                                  <Column7>
                                    <Div221>
                                      <Div222>
                                        <Div223>
                                          <Div224>
                                            <Base21>High</Base21>
                                            <Div225>4</Div225>
                                            <Img27
                                              loading="lazy"
                                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e0c2062b0e01872b6313c4d8cf082eabc64726a41c9c5ba1df723449f5c139bf?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                                            />
                                          </Div224>
                                          <Div226>
                                            <Img28
                                              loading="lazy"
                                              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/283fe39b869fd08535624866d03efe4856f0c66aa49a9b245be72e82e630887d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/283fe39b869fd08535624866d03efe4856f0c66aa49a9b245be72e82e630887d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/283fe39b869fd08535624866d03efe4856f0c66aa49a9b245be72e82e630887d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/283fe39b869fd08535624866d03efe4856f0c66aa49a9b245be72e82e630887d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/283fe39b869fd08535624866d03efe4856f0c66aa49a9b245be72e82e630887d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/283fe39b869fd08535624866d03efe4856f0c66aa49a9b245be72e82e630887d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/283fe39b869fd08535624866d03efe4856f0c66aa49a9b245be72e82e630887d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/283fe39b869fd08535624866d03efe4856f0c66aa49a9b245be72e82e630887d?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                                            />
                                            <Div227>2</Div227>
                                          </Div226>
                                        </Div223>
                                        <Div228>
                                          <Div229>
                                            <Base22>High</Base22>
                                            <Div230>4</Div230>
                                            <Img29
                                              loading="lazy"
                                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e0c2062b0e01872b6313c4d8cf082eabc64726a41c9c5ba1df723449f5c139bf?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                                            />
                                          </Div229>
                                          <Div231>
                                            <Div232>
                                              <Img30
                                                loading="lazy"
                                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d6762b718aa8c59cd7c9984c3770a31e2cad57f189b856e2026bf067cfe5cfcb?apiKey=a938513dc279413eaac5ce0f8c637f66&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6762b718aa8c59cd7c9984c3770a31e2cad57f189b856e2026bf067cfe5cfcb?apiKey=a938513dc279413eaac5ce0f8c637f66&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6762b718aa8c59cd7c9984c3770a31e2cad57f189b856e2026bf067cfe5cfcb?apiKey=a938513dc279413eaac5ce0f8c637f66&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6762b718aa8c59cd7c9984c3770a31e2cad57f189b856e2026bf067cfe5cfcb?apiKey=a938513dc279413eaac5ce0f8c637f66&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6762b718aa8c59cd7c9984c3770a31e2cad57f189b856e2026bf067cfe5cfcb?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6762b718aa8c59cd7c9984c3770a31e2cad57f189b856e2026bf067cfe5cfcb?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6762b718aa8c59cd7c9984c3770a31e2cad57f189b856e2026bf067cfe5cfcb?apiKey=a938513dc279413eaac5ce0f8c637f66&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6762b718aa8c59cd7c9984c3770a31e2cad57f189b856e2026bf067cfe5cfcb?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                                              />
                                              <Div233>2</Div233>
                                            </Div232>
                                            <Img31
                                              loading="lazy"
                                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/56596524187c944e11c3c9abc995e24e7560cac92612bd4c0f0e81c4dd69773c?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                                            />
                                          </Div231>
                                        </Div228>
                                        <Div234>
                                          <Div235>
                                            <Base23>High</Base23>
                                            <Div236>4</Div236>
                                            <Img32
                                              loading="lazy"
                                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e0c2062b0e01872b6313c4d8cf082eabc64726a41c9c5ba1df723449f5c139bf?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                                            />
                                          </Div235>
                                          <Div237>
                                            <Img33
                                              loading="lazy"
                                              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                                            />
                                            <Div238>3</Div238>
                                          </Div237>
                                        </Div234>
                                        <Div239>
                                          <Div240>
                                            <Base24>High</Base24>
                                            <Div241>4</Div241>
                                            <Img34
                                              loading="lazy"
                                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e0c2062b0e01872b6313c4d8cf082eabc64726a41c9c5ba1df723449f5c139bf?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                                            />
                                          </Div240>
                                          <Div242>
                                            <Img35
                                              loading="lazy"
                                              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/50f57434ab24e7cdf98e7c57bd0dd342bb98011df5ef6c68178f13eef5c11111?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                                            />
                                            <Div243>3</Div243>
                                          </Div242>
                                        </Div239>
                                        <Div244>
                                          <Div245>
                                            <Base25>High</Base25>
                                            <Div246>4</Div246>
                                            <Img36
                                              loading="lazy"
                                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e0c2062b0e01872b6313c4d8cf082eabc64726a41c9c5ba1df723449f5c139bf?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                                            />
                                          </Div245>
                                          <Div247>
                                            <Div248>
                                              <Img37
                                                loading="lazy"
                                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/2afbc186315ea1eaa0c358e9f00cb4e2a0a8759adea75544f9314457259d3ac0?apiKey=a938513dc279413eaac5ce0f8c637f66&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/2afbc186315ea1eaa0c358e9f00cb4e2a0a8759adea75544f9314457259d3ac0?apiKey=a938513dc279413eaac5ce0f8c637f66&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2afbc186315ea1eaa0c358e9f00cb4e2a0a8759adea75544f9314457259d3ac0?apiKey=a938513dc279413eaac5ce0f8c637f66&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/2afbc186315ea1eaa0c358e9f00cb4e2a0a8759adea75544f9314457259d3ac0?apiKey=a938513dc279413eaac5ce0f8c637f66&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/2afbc186315ea1eaa0c358e9f00cb4e2a0a8759adea75544f9314457259d3ac0?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2afbc186315ea1eaa0c358e9f00cb4e2a0a8759adea75544f9314457259d3ac0?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/2afbc186315ea1eaa0c358e9f00cb4e2a0a8759adea75544f9314457259d3ac0?apiKey=a938513dc279413eaac5ce0f8c637f66&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/2afbc186315ea1eaa0c358e9f00cb4e2a0a8759adea75544f9314457259d3ac0?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                                              />
                                              <Div249>3</Div249>
                                            </Div248>
                                            <Img38
                                              loading="lazy"
                                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/56596524187c944e11c3c9abc995e24e7560cac92612bd4c0f0e81c4dd69773c?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                                            />
                                          </Div247>
                                        </Div244>
                                      </Div222>
                                      <Div250 />
                                    </Div221>
                                  </Column7>
                                </Div203>
                              </Div202>
                            </Div189>
                          </Column4>
                          <Column8>
                            <Img39
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/a9c2c490c71e92a5ba737b59dabd9530d21687d100bb75123dd45d3df3d76265?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                            />
                          </Column8>
                        </Div188>
                      </Div187>
                      <Div251 />
                    </Div174>
                    <Div252>
                      <Div253>
                        <Space />
                        <Div254>Yesterday , 2 P.M</Div254>
                        <Div255>
                          <Base26>High</Base26>
                          <Img40
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e0c2062b0e01872b6313c4d8cf082eabc64726a41c9c5ba1df723449f5c139bf?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                          />
                        </Div255>
                        <Div256>
                          <Img41
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d96f44cfe65cdcc60a685d3ce4d15b3083811d247571303530ac8adbcfb0843d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d96f44cfe65cdcc60a685d3ce4d15b3083811d247571303530ac8adbcfb0843d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d96f44cfe65cdcc60a685d3ce4d15b3083811d247571303530ac8adbcfb0843d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d96f44cfe65cdcc60a685d3ce4d15b3083811d247571303530ac8adbcfb0843d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d96f44cfe65cdcc60a685d3ce4d15b3083811d247571303530ac8adbcfb0843d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d96f44cfe65cdcc60a685d3ce4d15b3083811d247571303530ac8adbcfb0843d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d96f44cfe65cdcc60a685d3ce4d15b3083811d247571303530ac8adbcfb0843d?apiKey=a938513dc279413eaac5ce0f8c637f66&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d96f44cfe65cdcc60a685d3ce4d15b3083811d247571303530ac8adbcfb0843d?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                          />
                          <Div257>2</Div257>
                        </Div256>
                      </Div253>
                      <Div258>
                        <Div259>
                          <Div260>
                            <Div261>4</Div261>
                            <Div262>
                              Use “Z” key on keyboard to resize view{" "}
                            </Div262>
                          </Div260>
                          <Div263>Edit Algorithm</Div263>
                        </Div259>
                        <Div264>
                          <Div265>Table of Contents</Div265>
                        </Div264>
                      </Div258>
                      <Div266>
                        <Div267>
                          <Space7 />
                          <Div268>456771</Div268>
                        </Div267>
                        <Div269>Yesterday , 2 P.M</Div269>
                        <Div270>
                          <Base27>High</Base27>
                          <Div271>4</Div271>
                          <Img42
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca516fed22b29447e20fc4983fee751e4015561b8a543129d7a648032cc5dc75?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                          />
                        </Div270>
                        <Div272>
                          <Img43
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ffb3463286dc8cffae91b74e66ec42ad66640366f85b3a37a3284632b066f225?apiKey=a938513dc279413eaac5ce0f8c637f66&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ffb3463286dc8cffae91b74e66ec42ad66640366f85b3a37a3284632b066f225?apiKey=a938513dc279413eaac5ce0f8c637f66&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ffb3463286dc8cffae91b74e66ec42ad66640366f85b3a37a3284632b066f225?apiKey=a938513dc279413eaac5ce0f8c637f66&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ffb3463286dc8cffae91b74e66ec42ad66640366f85b3a37a3284632b066f225?apiKey=a938513dc279413eaac5ce0f8c637f66&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ffb3463286dc8cffae91b74e66ec42ad66640366f85b3a37a3284632b066f225?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ffb3463286dc8cffae91b74e66ec42ad66640366f85b3a37a3284632b066f225?apiKey=a938513dc279413eaac5ce0f8c637f66&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ffb3463286dc8cffae91b74e66ec42ad66640366f85b3a37a3284632b066f225?apiKey=a938513dc279413eaac5ce0f8c637f66&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ffb3463286dc8cffae91b74e66ec42ad66640366f85b3a37a3284632b066f225?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                          />
                          <Div273>1</Div273>
                        </Div272>
                      </Div266>
                    </Div252>
                  </Div173>
                </div>
                <Column9>
                  <Div274>
                    <Div275>
                      <Div276>Impaired Sites</Div276>
                      <Div277>
                        <Base28 />
                        <Img44
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/47d979fdee27878479e317d90eb573c6f1fabcb899cd4542991e4d5a81f3bcd0?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                        />
                      </Div277>
                    </Div275>
                    <Div278>
                      <Div279>
                        <Base29>Site Name</Base29>
                        <Div280>
                          <Div281>Groveville</Div281>
                        </Div280>
                        <Div282>
                          <Div283>Laurelton</Div283>
                        </Div282>
                        <Div284>
                          <Div285>Primrose</Div285>
                        </Div284>
                        <Base30 />
                        <Base31 />
                        <Base32 />
                        <Base33 />
                        <Base34 />
                        <Base35 />
                      </Div279>
                      <Base />
                      <Base />
                      <Base />
                    </Div278>
                  </Div274>
                </Column9>
              </Div172>
            </Div171>
          </Div39>
        </Div19>
      </Div2>
    </Div>
  );
}

const Div = styled.div`
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div2 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div3 = styled.div`
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div4 = styled.div`
  background-color: var(--Grey-Grey-1, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div5 = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 5px 0;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div6 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div7 = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Base1 = styled(Base)`
  align-self: stretch;
  justify-content: center;
`;

const Base3 = styled(Base)`
  align-self: stretch;
  width: 24px;
  height: 24px;
  margin: auto 0;
`;

const Base4 = styled(Base)`
  align-self: stretch;
  width: 24px;
  height: 24px;
  margin: auto 0;
`;

const Img = styled.img`
  aspect-ratio: 0.85;
  object-fit: auto;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
`;

const Base5 = styled(Base)`
  align-self: stretch;
  width: 24px;
  height: 24px;
  margin: auto 0;
`;

const Div8 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div9 = styled.div`
  border-color: rgba(216, 218, 218, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--gray-85-ld-8-dadad-333333, #d8dada);
  z-index: 10;
  min-height: 1px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div10 = styled.div`
  display: flex;
  margin-left: 10px;
  width: 150px;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const Div11 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div12 = styled.div`
  justify-content: space-between;
  background-color: var(--blackl-000000-dffffff, #000);
  display: flex;
  width: 100%;
  gap: 12px;
  font-size: 14px;
  color: var(--whitelffffffd-000000, #fff);
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 143%;
  padding: 6px 8px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div13 = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 2px 0;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const Div14 = styled.div`
  display: flex;
  gap: 8px;
  margin: auto 0;
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
`;

const Div15 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Verizon NHG eDS, sans-serif;
  justify-content: center;
  padding: 0 20px;
`;

const Div16 = styled.div`
  display: flex;
  gap: 8px;
  white-space: nowrap;
  margin: auto 0;
  padding: 0 20px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img4 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
`;

const Div17 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Verizon NHG eDS, sans-serif;
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img5 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
`;

const Div18 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Verizon NHG eDS, sans-serif;
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div19 = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div20 = styled.div`
  justify-content: space-between;
  background-color: #fff;
  align-self: start;
  display: flex;
  flex-direction: column;
  padding: 1px 12px 27px;
  @media (max-width: 991px) {
    display: none;
  }
`;

const Div21 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    display: none;
  }
`;

const Div22 = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    display: none;
  }
`;

const Div23 = styled.div`
  display: flex;
  flex-direction: column;
`;

const Base6 = styled(Base)`
  justify-content: center;
`;

const Div24 = styled.div`
  color: #e00;
  text-align: center;
  font: 700 11px Verizon NHG eDS, sans-serif;
`;

const Div25 = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  padding: 0 7px;
`;

const Base7 = styled(Base)`
  justify-content: center;
`;

const Div26 = styled.div`
  color: #000;
  text-align: center;
  font: 700 11px Verizon NHG eDS, sans-serif;
`;

const Div27 = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  padding: 0 7px;
`;

const Base8 = styled(Base)`
  justify-content: center;
`;

const Div28 = styled.div`
  color: #000;
  text-align: center;
  font: 700 11px Verizon NHG eDS, sans-serif;
`;

const Div29 = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  padding: 0 7px;
`;

const Base9 = styled(Base)`
  justify-content: center;
`;

const Div30 = styled.div`
  color: #000;
  text-align: center;
  font: 700 11px Verizon NHG eDS, sans-serif;
`;

const Div31 = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  padding: 0 7px;
`;

const Base10 = styled(Base)`
  justify-content: center;
`;

const Div32 = styled.div`
  color: #000;
  text-align: center;
  font: 700 11px Verizon NHG eDS, sans-serif;
`;

const Div33 = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  padding: 0 7px;
`;

const Base11 = styled(Base)`
  justify-content: center;
`;

const Div34 = styled.div`
  color: #000;
  text-align: center;
  font: 700 11px Verizon NHG eDS, sans-serif;
`;

const Div35 = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  padding: 0 7px;
`;

const Base12 = styled(Base)`
  justify-content: center;
`;

const Div36 = styled.div`
  color: #000;
  text-align: center;
  font: 700 11px Verizon NHG eDS, sans-serif;
`;

const Div37 = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  padding: 0 7px;
`;

const Base13 = styled(Base)`
  justify-content: center;
`;

const Div38 = styled.div`
  color: #000;
  text-align: center;
  font: 700 11px Verizon NHG eDS, sans-serif;
`;

const Img6 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
  align-self: center;
  margin-top: 97px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div39 = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div40 = styled.div`
  z-index: 10;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div41 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 73%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div42 = styled.div`
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  padding: 0 16px 19px 8px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 8px;
  }
`;

const Div43 = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div44 = styled.div`
  color: #000;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 700 16px Verizon NHG eDS, sans-serif;
`;

const Div45 = styled.div`
  display: flex;
  align-items: start;
  gap: 7px;
`;

const Div46 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Base14 = styled(Base)`
  justify-content: center;
`;

const Div47 = styled.div`
  border-color: rgba(216, 218, 218, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #d8dada;
  margin-top: 17px;
  width: 1px;
  height: 29px;
`;

const Div48 = styled.div`
  display: flex;
  margin-top: 12px;
  gap: 0px;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  line-height: 133%;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div49 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Verizon NHG eDS, sans-serif;
  justify-content: center;
  border-radius: 22px 0px 0px 22px;
  border-color: rgba(0, 0, 0, 1);
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  background-color: #fff;
  color: #000;
  padding: 8px 13px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div50 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Verizon NHG eDS, sans-serif;
  justify-content: center;
  align-items: start;
  border-radius: 0px 22px 22px 0px;
  border-color: rgba(0, 0, 0, 1);
  border-style: solid;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  background-color: #000;
  color: #fff;
  padding: 8px 16px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div51 = styled.div`
  display: flex;
  gap: 12px;
`;

const Base15 = styled(Base)`
  justify-content: center;
`;

const Img7 = styled.img`
  aspect-ratio: 1.05;
  object-fit: auto;
  object-position: center;
  width: 19px;
  align-self: start;
  margin-top: 20px;
`;

const Div52 = styled.div`
  display: flex;
  margin-top: 19px;
  align-items: end;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div53 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div54 = styled.div`
  display: flex;
  gap: 0px;
`;

const Div55 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div56 = styled.div`
  filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.22))
    drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
  background-color: var(--BLUE400-600, #0089ec);
  border-radius: 50%;
  width: 16px;
  height: 16px;
`;

const Div57 = styled.div`
  border-radius: 8px;
  background-color: var(--BLUE400-600, #0089ec);
  align-self: start;
  z-index: 10;
  margin-top: 4px;
  width: 98px;
  height: 8px;
`;

const Div58 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div59 = styled.div`
  filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.22))
    drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
  background-color: var(--BLUE400-600, #0089ec);
  border-radius: 50%;
  width: 16px;
  height: 16px;
`;

const Div60 = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 20px;
  font-size: 11px;
  color: #000;
  font-weight: 400;
  text-align: center;
  justify-content: space-between;
`;

const Div61 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div62 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div63 = styled.div`
  color: #000;
  text-align: center;
  margin-top: 32px;
  font: 400 11px Verizon NHG eDS, sans-serif;
`;

const Div64 = styled.div`
  color: #000;
  text-align: center;
  margin-top: 32px;
  font: 400 11px Verizon NHG eDS, sans-serif;
`;

const Div65 = styled.div`
  color: #000;
  text-align: center;
  margin-top: 32px;
  font: 400 11px Verizon NHG eDS, sans-serif;
`;

const Div66 = styled.div`
  color: #000;
  text-align: center;
  margin-top: 32px;
  font: 400 11px Verizon NHG eDS, sans-serif;
`;

const Div67 = styled.div`
  color: #000;
  text-align: center;
  margin-top: 32px;
  font: 400 11px Verizon NHG eDS, sans-serif;
`;

const Div68 = styled.div`
  color: #000;
  text-align: center;
  margin-top: 32px;
  font: 400 11px Verizon NHG eDS, sans-serif;
`;

const Div69 = styled.div`
  color: #000;
  text-align: center;
  margin-top: 32px;
  font: 400 11px Verizon NHG eDS, sans-serif;
`;

const Div70 = styled.div`
  color: #000;
  text-align: center;
  margin-top: 32px;
  font: 400 11px Verizon NHG eDS, sans-serif;
`;

const Div71 = styled.div`
  color: #000;
  text-align: center;
  margin-top: 32px;
  font: 400 11px Verizon NHG eDS, sans-serif;
`;

const Div72 = styled.div`
  color: #000;
  text-align: center;
  margin-top: 32px;
  font: 400 11px Verizon NHG eDS, sans-serif;
`;

const Div73 = styled.div`
  color: #000;
  text-align: center;
  margin-top: 32px;
  font: 400 11px Verizon NHG eDS, sans-serif;
`;

const Div74 = styled.div`
  display: flex;
  margin-top: 23px;
  gap: 11px;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div75 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div76 = styled.div`
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px 12px 8px;
`;

const Div77 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
`;

const Img8 = styled.img`
  aspect-ratio: 4.55;
  object-fit: auto;
  object-position: center;
  width: 133px;
  margin-top: 31px;
`;

const Div78 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 8px;
  gap: 20px;
  font-size: 10px;
  color: #6f7171;
  font-weight: 400;
  white-space: nowrap;
  justify-content: space-between;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div79 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div80 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div81 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div82 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 17px;
`;

const Div83 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  font-weight: 400;
  margin-top: 29px;
`;

const Div84 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div85 = styled.div`
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px 12px 8px;
`;

const Div86 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
`;

const Img9 = styled.img`
  aspect-ratio: 4.55;
  object-fit: auto;
  object-position: center;
  width: 133px;
  margin-top: 33px;
`;

const Div87 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 8px;
  gap: 20px;
  font-size: 10px;
  color: #6f7171;
  font-weight: 400;
  white-space: nowrap;
  justify-content: space-between;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div88 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div89 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div90 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div91 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 17px;
`;

const Div92 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  font-weight: 400;
  margin-top: 29px;
`;

const Div93 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div94 = styled.div`
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px 12px 8px;
`;

const Div95 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
`;

const Img10 = styled.img`
  aspect-ratio: 4.55;
  object-fit: auto;
  object-position: center;
  width: 133px;
  margin-top: 31px;
`;

const Div96 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 8px;
  gap: 20px;
  font-size: 10px;
  color: #6f7171;
  font-weight: 400;
  white-space: nowrap;
  justify-content: space-between;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div97 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div98 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div99 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div100 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 17px;
`;

const Div101 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  font-weight: 400;
  margin-top: 29px;
`;

const Div102 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div103 = styled.div`
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px 12px 8px;
`;

const Div104 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
`;

const Img11 = styled.img`
  aspect-ratio: 4.55;
  object-fit: auto;
  object-position: center;
  width: 133px;
  margin-top: 31px;
`;

const Div105 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 8px;
  gap: 20px;
  font-size: 10px;
  color: #6f7171;
  font-weight: 400;
  white-space: nowrap;
  justify-content: space-between;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div106 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div107 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div108 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div109 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 17px;
`;

const Div110 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  font-weight: 400;
  margin-top: 29px;
`;

const Div111 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div112 = styled.div`
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px 12px 8px;
`;

const Div113 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
`;

const Img12 = styled.img`
  aspect-ratio: 4.55;
  object-fit: auto;
  object-position: center;
  width: 133px;
  margin-top: 31px;
`;

const Div114 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 8px;
  gap: 20px;
  font-size: 10px;
  color: #6f7171;
  font-weight: 400;
  white-space: nowrap;
  justify-content: space-between;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div115 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div116 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div117 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div118 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 17px;
`;

const Div119 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  font-weight: 400;
  margin-top: 29px;
`;

const Img13 = styled.img`
  aspect-ratio: 1.08;
  object-fit: auto;
  object-position: center;
  width: 116px;
  align-self: start;
  margin-top: 15px;
  max-width: 100%;
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 27%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div120 = styled.div`
  border-radius: 6.202px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  margin: 0 auto;
  padding: 8px;
  @media (max-width: 991px) {
    margin-top: 8px;
  }
`;

const Div121 = styled.div`
  display: flex;
  gap: 20px;
  padding: 0 8px;
`;

const Div122 = styled.div`
  color: #000;
  flex: 1;
  margin: auto 0;
  font: 700 16px Verizon NHG eDS, sans-serif;
`;

const Base16 = styled(Base)`
  justify-content: center;
`;

const Div123 = styled.div`
  display: flex;
  gap: 0px;
  font-size: 14px;
  color: #0d9aff;
  font-weight: 500;
  line-height: 112%;
  margin: 7px 19px 0;
  @media (max-width: 991px) {
    margin: 0 10px;
  }
`;

const Div124 = styled.div`
  font-family: Helvetica Neue, sans-serif;
  text-decoration-line: underline;
  align-self: end;
  margin-top: 11px;
  padding: 70px 0 22px;
`;

const Div125 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  aspect-ratio: 1.89;
  width: 214px;
  align-items: end;
  text-align: right;
  padding: 11px 60px 76px;
`;

const Img14 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Div126 = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Img15 = styled.img`
  aspect-ratio: 12.5;
  object-fit: auto;
  object-position: center;
  width: 100%;
  stroke-width: 0.733px;
  stroke: var(---Brand-6, #165dff);
  border-color: rgba(22, 93, 255, 1);
  border-style: solid;
  border-width: 1px;
  align-self: center;
`;

const Div127 = styled.div`
  font-family: Helvetica Neue, sans-serif;
  text-decoration-line: underline;
`;

const Div128 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 8px;
  width: 259px;
  max-width: 100%;
  flex-direction: column;
  font-size: 8px;
  color: #000;
  font-weight: 400;
  justify-content: center;
  padding: 4px 8px;
`;

const Div129 = styled.div`
  display: flex;
  gap: 7px;
  justify-content: space-between;
  padding: 0 1px;
`;

const Div130 = styled.div`
  align-self: start;
  display: flex;
  gap: 7px;
  padding: 1px 0;
`;

const Div131 = styled.div`
  background-color: #e3736a;
  border-radius: 50%;
  align-self: start;
  width: 9px;
  height: 10px;
`;

const Div132 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div133 = styled.div`
  align-self: start;
  display: flex;
  gap: 7px;
  padding: 1px 0;
`;

const Div134 = styled.div`
  background-color: #165dff;
  border-radius: 50%;
  align-self: start;
  width: 9px;
  height: 10px;
`;

const Div135 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div136 = styled.div`
  display: flex;
  gap: 6px;
  white-space: nowrap;
  padding: 1px 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div137 = styled.div`
  background-color: #00b713;
  border-radius: 50%;
  align-self: start;
  width: 10px;
  height: 10px;
`;

const Div138 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div139 = styled.div`
  display: flex;
  gap: 6px;
  white-space: nowrap;
  padding: 1px 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div140 = styled.div`
  background-color: #bb1ed5;
  border-radius: 50%;
  align-self: start;
  width: 10px;
  height: 10px;
`;

const Div141 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div142 = styled.div`
  display: flex;
  margin-top: 7px;
  padding-right: 8px;
  flex-direction: column;
  font-size: 11px;
  color: var(--Grey-Grey-6, #000);
  font-weight: 700;
  white-space: nowrap;
  line-height: 104%;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div143 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Neue Haas Grotesk Display Std, -apple-system, Roboto, Helvetica,
    sans-serif;
  align-self: start;
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div144 = styled.div`
  border: 0px solid var(--Grey-Grey-4, #747676);
  background-color: var(--Grey-Grey-6, #000);
  margin-top: 4px;
  width: 333px;
  max-width: 100%;
  height: 1px;
  @media (max-width: 991px) {
    margin-left: 8px;
  }
`;

const Div145 = styled.div`
  background-color: #fff;
  display: flex;
  margin-top: 8px;
  gap: 6px;
  padding: 6px;
`;

const Div146 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Div147 = styled.div`
  justify-content: center;
  background-color: var(--Grey-Grey-1, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 11px;
  color: var(--Grey-Grey-6, #000);
  font-weight: 450;
  line-height: 120%;
  padding: 1px 0;
`;

const Div148 = styled.div`
  display: flex;
  gap: 7px;
  padding: 2px;
`;

const Div149 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Neue Haas Grotesk Display Std, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const Img16 = styled.img`
  aspect-ratio: 1.11;
  object-fit: auto;
  object-position: center;
  width: 10px;
  margin: auto 0;
`;

const Div150 = styled.div`
  justify-content: center;
  background-color: var(--Grey-Grey-1, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 11px;
  color: var(--Grey-Grey-6, #000);
  font-weight: 450;
  white-space: nowrap;
  line-height: 120%;
  padding: 1px 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div151 = styled.div`
  display: flex;
  gap: 7px;
  padding: 2px 2px 2px 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div152 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Neue Haas Grotesk Display Std, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const Img17 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 10px;
  margin: auto 0;
`;

const Div153 = styled.div`
  justify-content: center;
  background-color: var(--Grey-Grey-1, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 11px;
  color: var(--Grey-Grey-6, #000);
  font-weight: 450;
  line-height: 120%;
  padding: 1px 0;
`;

const Div154 = styled.div`
  display: flex;
  gap: 7px;
  padding: 2px;
`;

const Div155 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Neue Haas Grotesk Display Std, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const Img18 = styled.img`
  aspect-ratio: 1.11;
  object-fit: auto;
  object-position: center;
  width: 10px;
  margin: auto 0;
`;

const Div156 = styled.div`
  justify-content: center;
  background-color: var(--Grey-Grey-1, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1px 0;
`;

const Div157 = styled.div`
  display: flex;
  padding-left: 2px;
  gap: 0px;
`;

const Div158 = styled.div`
  color: var(--Grey-Grey-6, #000);
  font-feature-settings: "clig" off, "liga" off;
  font: 450 11px/120% Neue Haas Grotesk Display Std, -apple-system, Roboto,
    Helvetica, sans-serif;
`;

const Div159 = styled.div`
  justify-content: center;
  align-items: start;
  align-self: end;
  z-index: 10;
  display: flex;
  margin-top: -23px;
  flex-direction: column;
  padding: 26px 36px 7px;
  @media (max-width: 991px) {
    padding-right: 20px;
  }
`;

const Img19 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 10px;
`;

const Div160 = styled.div`
  align-self: start;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  color: var(--Grey-Grey-6, #000);
  font-weight: 450;
  line-height: 120%;
  flex: 1;
`;

const Div161 = styled.div`
  justify-content: center;
  background-color: var(--Grey-Grey-1, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1px 0;
`;

const Div162 = styled.div`
  display: flex;
  gap: 7px;
  padding: 2px;
`;

const Div163 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Neue Haas Grotesk Display Std, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const Img20 = styled.img`
  aspect-ratio: 1.11;
  object-fit: auto;
  object-position: center;
  width: 10px;
  margin: auto 0;
`;

const Div164 = styled.div`
  justify-content: center;
  background-color: var(--Grey-Grey-1, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1px 0;
`;

const Div165 = styled.div`
  display: flex;
  gap: 7px;
  padding: 2px;
`;

const Div166 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Neue Haas Grotesk Display Std, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const Img21 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 10px;
  margin: auto 0;
`;

const Div167 = styled.div`
  justify-content: center;
  background-color: var(--Grey-Grey-1, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  white-space: nowrap;
  padding: 1px 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div168 = styled.div`
  display: flex;
  gap: 7px;
  padding: 2px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div169 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Neue Haas Grotesk Display Std, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const Img22 = styled.img`
  aspect-ratio: 1.11;
  object-fit: auto;
  object-position: center;
  width: 10px;
  margin: auto 0;
`;

const Div170 = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 34px;
  margin: -28px 0 0 283px;
  padding: 10px 5px;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const Img23 = styled.img`
  aspect-ratio: 1.82;
  object-fit: auto;
  object-position: center;
  width: 100%;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
`;

const Div171 = styled.div`
  margin-top: 4px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div172 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Div173 = styled.div`
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 8px;
  }
`;

const Div174 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 14px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div175 = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  gap: 20px;
  justify-content: space-between;
  padding: 0 1px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div176 = styled.div`
  display: flex;
  flex-direction: column;
`;

const Div177 = styled.div`
  color: var(--Grey-Grey-6, #000);
  text-align: center;
  -webkit-text-stroke-width: 2;
  -webkit-text-stroke-color: var(--Gray-gray-1, #fff);
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px;
  align-self: end;
  margin-right: 41px;
  font: 450 10px/110% Neue Haas Grotesk Display Std, -apple-system, Roboto,
    Helvetica, sans-serif;
  @media (max-width: 991px) {
    margin-right: 10px;
  }
`;

const Div178 = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 20px;
  font-size: 14px;
  color: #000;
  font-weight: 700;
`;

const Div179 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Div180 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Img24 = styled.img`
  aspect-ratio: 50;
  object-fit: auto;
  object-position: center;
  width: 160px;
  stroke-width: 3px;
  stroke: #e00;
  border-color: rgba(238, 0, 0, 1);
  border-style: solid;
  border-width: 3px;
  align-self: center;
`;

const Div181 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;

const Div182 = styled.div`
  display: flex;
  margin-top: 11px;
  gap: 7px;
  font-size: 12px;
  color: #000;
  font-weight: 450;
`;

const Div183 = styled.div`
  border-radius: 4px;
  border-color: rgba(167, 167, 167, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 9px;
`;

const Div184 = styled.div`
  font-family: Neue Haas Grotesk Display Std, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const Img25 = styled.img`
  aspect-ratio: 1.75;
  object-fit: auto;
  object-position: center;
  width: 7px;
  fill: #000;
  stroke-width: 1px;
  stroke: #000;
  border-color: rgba(0, 0, 0, 1);
  border-style: solid;
  border-width: 1px;
  align-self: start;
`;

const Div185 = styled.div`
  border-radius: 4px;
  border-color: rgba(167, 167, 167, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  align-self: start;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 9px 10px;
`;

const Div186 = styled.div`
  font-family: Neue Haas Grotesk Display Std, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const Img26 = styled.img`
  aspect-ratio: 1.75;
  object-fit: auto;
  object-position: center;
  width: 7px;
  fill: #000;
  stroke-width: 1px;
  stroke: #000;
  border-color: rgba(0, 0, 0, 1);
  border-style: solid;
  border-width: 1px;
  align-self: start;
  margin-top: 4px;
`;

const Div187 = styled.div`
  margin-top: 15px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div188 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column4 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 70%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div189 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 12px;
  }
`;

const Div190 = styled.div`
  justify-content: center;
  align-items: end;
  background-color: var(--GRAY95, #f6f6f6);
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-left: 20px;
  }
`;

const Div191 = styled.div`
  display: flex;
  width: 112px;
  max-width: 100%;
  padding-right: 12px;
  gap: 12px;
`;

const Div192 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Base17 = styled(Base)`
  height: 16px;
`;

const Div193 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Base18 = styled(Base)`
  height: 16px;
`;

const Div194 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Base19 = styled(Base)`
  height: 16px;
`;

const Div195 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Base20 = styled(Base)`
  height: 16px;
`;

const Div196 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 23px;
  width: 580px;
  max-width: 100%;
  gap: 20px;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div197 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div198 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div199 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;

const Div200 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div201 = styled.div`
  border-color: rgba(0, 0, 0, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #000;
  margin-top: 13px;
  height: 1px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div202 = styled.div`
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div203 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column5 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 26%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div204 = styled.div`
  display: flex;
  margin-top: 17px;
  flex-direction: column;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div205 = styled.div`
  display: flex;
  gap: 14px;
`;

const Space1 = styled(Space)`
  width: 20px;
  height: 20px;
`;

const Div206 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
`;

const Div207 = styled.div`
  display: flex;
  margin-top: 32px;
  gap: 14px;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div208 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
`;

const Div209 = styled.div`
  display: flex;
  margin-top: 27px;
  gap: 14px;
`;

const Div210 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;

const Div211 = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 14px;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div212 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;

const Div213 = styled.div`
  display: flex;
  margin-top: 32px;
  gap: 14px;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div214 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  align-self: start;
  margin-top: 10px;
  flex-grow: 1;
  flex-basis: auto;
`;

const Column6 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 23%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div215 = styled.div`
  display: flex;
  margin-top: 23px;
  flex-direction: column;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div216 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div217 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 39px;
`;

const Div218 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 39px;
`;

const Div219 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 41px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div220 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 42px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Column7 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 51%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div221 = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: start;
  gap: 20px;
  white-space: nowrap;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div222 = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div223 = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    padding-right: 20px;
    white-space: initial;
  }
`;

const Div224 = styled.div`
  display: flex;
  gap: 11px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Base21 = styled(Base)`
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div225 = styled.div`
  color: #000;
  margin: auto 0;
  font: 700 12px Verizon NHG eDS, sans-serif;
`;

const Img27 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 19px;
  align-self: start;
`;

const Div226 = styled.div`
  display: flex;
  gap: 4px;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img28 = styled.img`
  aspect-ratio: 0.75;
  object-fit: auto;
  object-position: center;
  width: 15px;
`;

const Div227 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div228 = styled.div`
  display: flex;
  margin-top: 28px;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div229 = styled.div`
  align-self: start;
  display: flex;
  gap: 11px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Base22 = styled(Base)`
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div230 = styled.div`
  color: #000;
  margin: auto 0;
  font: 700 12px Verizon NHG eDS, sans-serif;
`;

const Img29 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 19px;
  align-self: start;
`;

const Div231 = styled.div`
  display: flex;
  gap: 9px;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div232 = styled.div`
  display: flex;
  gap: 4px;
  margin: auto 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img30 = styled.img`
  aspect-ratio: 0.7;
  object-fit: auto;
  object-position: center;
  width: 14px;
`;

const Div233 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  margin: auto 0;
`;

const Img31 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const Div234 = styled.div`
  display: flex;
  margin-top: 28px;
  width: 100%;
  align-items: start;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    padding-right: 20px;
    white-space: initial;
  }
`;

const Div235 = styled.div`
  display: flex;
  margin-top: 4px;
  gap: 11px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Base23 = styled(Base)`
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div236 = styled.div`
  color: #000;
  margin: auto 0;
  font: 700 12px Verizon NHG eDS, sans-serif;
`;

const Img32 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 19px;
  align-self: start;
`;

const Div237 = styled.div`
  display: flex;
  gap: 4px;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img33 = styled.img`
  aspect-ratio: 0.79;
  object-fit: auto;
  object-position: center;
  width: 15px;
`;

const Div238 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  margin: auto 0;
`;

const Div239 = styled.div`
  display: flex;
  margin-top: 28px;
  width: 100%;
  align-items: start;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    padding-right: 20px;
    white-space: initial;
  }
`;

const Div240 = styled.div`
  display: flex;
  margin-top: 5px;
  gap: 11px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Base24 = styled(Base)`
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div241 = styled.div`
  color: #000;
  margin: auto 0;
  font: 700 12px Verizon NHG eDS, sans-serif;
`;

const Img34 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 19px;
  align-self: start;
`;

const Div242 = styled.div`
  display: flex;
  gap: 4px;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img35 = styled.img`
  aspect-ratio: 0.79;
  object-fit: auto;
  object-position: center;
  width: 15px;
`;

const Div243 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  margin: auto 0;
`;

const Div244 = styled.div`
  display: flex;
  margin-top: 26px;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
  padding: 0 1px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div245 = styled.div`
  align-self: start;
  display: flex;
  margin-top: 4px;
  gap: 11px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Base25 = styled(Base)`
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div246 = styled.div`
  color: #000;
  margin: auto 0;
  font: 700 12px Verizon NHG eDS, sans-serif;
`;

const Img36 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 19px;
  align-self: start;
`;

const Div247 = styled.div`
  display: flex;
  gap: 7px;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div248 = styled.div`
  display: flex;
  gap: 3px;
  margin: auto 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img37 = styled.img`
  aspect-ratio: 0.79;
  object-fit: auto;
  object-position: center;
  width: 15px;
`;

const Div249 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  margin: auto 0;
`;

const Img38 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const Div250 = styled.div`
  border-radius: var(--Number, 1px);
  background-color: #d9d9d9;
  width: 6px;
  height: 215px;
`;

const Column8 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 30%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img39 = styled.img`
  aspect-ratio: 1.03;
  object-fit: auto;
  object-position: center;
  width: 100%;
  align-self: stretch;
  margin: auto 0;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div251 = styled.div`
  border-color: rgba(216, 218, 218, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #d8dada;
  margin-top: 13px;
  height: 1px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div252 = styled.div`
  align-items: start;
  background-color: #ddd;
  display: flex;
  margin-top: 9px;
  width: 100%;
  flex-direction: column;
  padding: 3px 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div253 = styled.div`
  display: flex;
  margin-left: 72px;
  width: 610px;
  max-width: 100%;
  align-items: start;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div254 = styled.div`
  color: #000;
  align-self: stretch;
  margin: auto 0;
  font: 700 12px Verizon NHG eDS, sans-serif;
`;

const Div255 = styled.div`
  display: flex;
  gap: 20px;
  white-space: nowrap;
  justify-content: space-between;
  padding: 0 1px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Base26 = styled(Base)`
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img40 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 19px;
  align-self: start;
`;

const Div256 = styled.div`
  display: flex;
  gap: 5px;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img41 = styled.img`
  aspect-ratio: 0.7;
  object-fit: auto;
  object-position: center;
  width: 14px;
`;

const Div257 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
  margin: auto 0;
`;

const Div258 = styled.div`
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  width: 100%;
  gap: 20px;
  font-weight: 700;
  padding: 0 1px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div259 = styled.div`
  align-self: start;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div260 = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div261 = styled.div`
  color: #000;
  align-self: end;
  font: 12px Verizon NHG eDS, sans-serif;
`;

const Div262 = styled.div`
  color: var(--gray-20-l-333333-dffffff, #333);
  font-feature-settings: "clig" off, "liga" off;
  letter-spacing: 0.5px;
  font: 16px Verizon NHG eDS, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div263 = styled.div`
  color: var(--gray-20-l-333333-dffffff, #333);
  font-feature-settings: "clig" off, "liga" off;
  letter-spacing: 0.5px;
  align-self: start;
  margin-top: 12px;
  font: 16px Neue Haas Grotesk Display Std, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const Div264 = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: var(--blackl-000000-dffffff, #000);
  letter-spacing: 0.5px;
  justify-content: center;
`;

const Div265 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Verizon NHG eDS, sans-serif;
  border-radius: 22px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--whitelffffffd-000000, #fff);
  justify-content: center;
  padding: 12px 24px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Div266 = styled.div`
  display: flex;
  margin-left: 72px;
  width: 606px;
  max-width: 100%;
  gap: 20px;
  justify-content: space-between;
  padding: 0 1px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div267 = styled.div`
  align-self: start;
  display: flex;
  margin-top: 4px;
  gap: 14px;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Space7 = styled(Space)`
  width: 20px;
  height: 9px;
`;

const Div268 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div269 = styled.div`
  color: #000;
  font: 700 12px Verizon NHG eDS, sans-serif;
`;

const Div270 = styled.div`
  display: flex;
  gap: 11px;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Base27 = styled(Base)`
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div271 = styled.div`
  color: #000;
  font: 700 12px Verizon NHG eDS, sans-serif;
`;

const Img42 = styled.img`
  aspect-ratio: 1.59;
  object-fit: auto;
  object-position: center;
  width: 19px;
  align-self: start;
`;

const Div272 = styled.div`
  align-self: start;
  display: flex;
  gap: 4px;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img43 = styled.img`
  aspect-ratio: 1.37;
  object-fit: auto;
  object-position: center;
  width: 15px;
`;

const Div273 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Column9 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 27%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div274 = styled.div`
  border-radius: 10px;
  background-color: var(--whitelffffffd-000000, #fff);
  display: flex;
  width: 100%;
  flex-grow: 1;
  padding-top: 8px;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 991px) {
    margin-top: 8px;
  }
`;

const Div275 = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 0 8px;
`;

const Div276 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  color: var(--blackl-000000-dffffff, #000);
  justify-content: center;
  margin: auto 0;
  padding: 0 20px;
  font: 700 14px/28px Verizon NHG eDS, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const Div277 = styled.div`
  justify-content: end;
  display: flex;
  gap: 8px;
`;

const Base28 = styled(Base)`
  justify-content: center;
`;

const Img44 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
  margin: auto 0;
`;

const Div278 = styled.div`
  display: flex;
  margin-top: 8px;
  padding-left: 41px;
  gap: 0px;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const Div279 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Base29 = styled(Base)`
  justify-content: center;
`;

const Div280 = styled.div`
  align-items: start;
  border-color: rgba(216, 218, 218, 1);
  border-style: solid;
  border-bottom-width: 1px;
  background-color: var(--whitelffffffd-000000, #fff);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: var(--Blue-Blue-3, var(--blue-38-l-0077-b-4-d-0089-ec, #0077b4));
  font-weight: 400;
  white-space: nowrap;
  letter-spacing: 0.5px;
  line-height: 167%;
  justify-content: center;
  padding: 12px;
  @media (max-width: 991px) {
    padding-right: 20px;
    white-space: initial;
  }
`;

const Div281 = styled.div`
  max-width: 200px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: ellipsis;
  font-family: Verizon NHG eDS, sans-serif;
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div282 = styled.div`
  align-items: start;
  border-color: rgba(216, 218, 218, 1);
  border-style: solid;
  border-bottom-width: 1px;
  background-color: var(--whitelffffffd-000000, #fff);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: var(--Blue-Blue-3, var(--blue-38-l-0077-b-4-d-0089-ec, #0077b4));
  font-weight: 400;
  white-space: nowrap;
  letter-spacing: 0.5px;
  line-height: 167%;
  justify-content: center;
  padding: 12px;
  @media (max-width: 991px) {
    padding-right: 20px;
    white-space: initial;
  }
`;

const Div283 = styled.div`
  max-width: 200px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: ellipsis;
  font-family: Verizon NHG eDS, sans-serif;
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div284 = styled.div`
  align-items: start;
  border-color: rgba(216, 218, 218, 1);
  border-style: solid;
  border-bottom-width: 1px;
  background-color: var(--whitelffffffd-000000, #fff);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: var(--Blue-Blue-3, var(--blue-38-l-0077-b-4-d-0089-ec, #0077b4));
  font-weight: 400;
  white-space: nowrap;
  letter-spacing: 0.5px;
  line-height: 167%;
  justify-content: center;
  padding: 12px;
  @media (max-width: 991px) {
    padding-right: 20px;
    white-space: initial;
  }
`;

const Div285 = styled.div`
  max-width: 200px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: ellipsis;
  font-family: Verizon NHG eDS, sans-serif;
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Base30 = styled(Base)`
  justify-content: center;
`;

const Base31 = styled(Base)`
  justify-content: center;
`;

const Base32 = styled(Base)`
  justify-content: center;
`;

const Base33 = styled(Base)`
  justify-content: center;
`;

const Base34 = styled(Base)`
  justify-content: center;
`;

const Base35 = styled(Base)`
  justify-content: center;
`;


