import * as React from "react";

function Hamburger() {
  return (
    <button className="hamburger">
      <div className="bar" />
      <div className="bar" />
      <div className="bar" />
      
      <style jsx>{`
        .hamburger {
          display: flex;
          flex-direction: column; 
          justify-content: space-around;
          width: 2rem;
          height: 2rem;
          background: transparent;
          border: none;
          cursor: pointer;
          padding: 0;
        }
        
        .bar {
          width: 2rem;
          height: 0.25rem;
          background: black;
          border-radius: 10px;
        }
      `}</style>
    </button>
  );
}

export  default Hamburger;
