import * as React from "react";
// import Base from "@/components/Base";
import Base from "./Base";

export default function Page4() {
  return (
    <>
      <div className="div">
        <div className="div-2">
          <div className="column">
            <div className="div-3">
              <div className="div-4">
                <div className="div-5">
                  <div className="div-6">
                    <div className="div-7">
                      <div className="div-8">Tasks and Tickets</div>
                    </div>
                    <Base className="base" />
                  </div>
                  <div className="div-9">
                    <div className="div-10">
                      <Base className="base-2" />
                      <div className="div-11">
                        <div className="div-12">My Tasks</div>
                      </div>
                    </div>
                    <div className="div-13">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/753fe0f2776c44294b0a795271da4a6ce4df223740f438a3b126b5234e278171?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                        className="img"
                      />
                      <div className="div-14">
                        <div className="div-15">Working</div>
                      </div>
                    </div>
                    <div className="div-16">
                      <div className="div-17">
                        <div className="div-18">WO000003232323</div>
                        <div className="div-19">
                          <div className="div-20">[Equipment]</div>
                          <div className="div-21">[Medium]</div>
                        </div>
                        <div className="div-22">LTE - ENODEB - CELL</div>
                      </div>
                    </div>
                    <div className="div-23">
                      <div className="div-24">
                        <div className="div-25">WO000003232323</div>
                        <div className="div-26">
                          <div className="div-27">[Equipment]</div>
                          <div className="div-28">[Low]</div>
                        </div>
                        <div className="div-29">LTE - ENODEB - CELL</div>
                      </div>
                    </div>
                    <div className="div-30">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/753fe0f2776c44294b0a795271da4a6ce4df223740f438a3b126b5234e278171?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                        className="img"
                      />
                      <div className="div-31">
                        <div className="div-32">Backlog</div>
                      </div>
                    </div>
                  </div>
                  <div className="div-33">
                    <div className="div-34">
                      <Base className="base-3" />
                      <div className="div-35">
                        <div className="div-36">NOC Tickets</div>
                      </div>
                    </div>
                    <div className="div-37">
                      <div className="div-38">
                        <div className="div-39">WO000003232323</div>
                        <div className="div-40">
                          <div className="div-41">[Equipment]</div>
                          <div className="div-42">[Critical]</div>
                        </div>
                        <div className="div-43">LTE - ENODEB - CELL</div>
                      </div>
                    </div>
                    <div className="div-44">
                      <div className="div-45">
                        <div className="div-46">WO000003232323</div>
                        <div className="div-47">
                          <div className="div-48">[Equipment]</div>
                          <div className="div-49">[Critical]</div>
                        </div>
                        <div className="div-50">LTE - ENODEB - CELL</div>
                      </div>
                    </div>
                    <div className="div-51">
                      <div className="div-52">
                        <div className="div-53">WO000003232323</div>
                        <div className="div-54">
                          <div className="div-55">[Equipment]</div>
                          <div className="div-56">[Medium]</div>
                        </div>
                        <div className="div-57">LTE - ENODEB - CELL</div>
                      </div>
                    </div>
                    <div className="div-58">
                      <div className="div-59">
                        <div className="div-60">WO000003232323</div>
                        <div className="div-61">
                          <div className="div-62">[Equipment]</div>
                          <div className="div-63">[Low]</div>
                        </div>
                        <div className="div-64">LTE - ENODEB - CELL</div>
                      </div>
                    </div>
                  </div>
                  <div className="div-65">
                    <div className="div-66">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/753fe0f2776c44294b0a795271da4a6ce4df223740f438a3b126b5234e278171?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                        className="img"
                      />
                      <div className="div-67">
                        <div className="div-68">IOP Work Task</div>
                      </div>
                    </div>
                  </div>
                  <div className="div-69">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/753fe0f2776c44294b0a795271da4a6ce4df223740f438a3b126b5234e278171?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                      className="img"
                    />
                    <div className="div-70">
                      <div className="div-71">IOP Vendor WO</div>
                    </div>
                  </div>
                  <div className="div-72">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/753fe0f2776c44294b0a795271da4a6ce4df223740f438a3b126b5234e278171?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                      className="img"
                    />
                    <div className="div-73">
                      <div className="div-74">FAST Tasks</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-75">Close</div>
            </div>
          </div>
          <div className="column-2">
            <div className="div-76">
              <div className="div-77">
                <div className="div-78">
                  <div className="div-79">154271_WEST_EDGEFIELD</div>
                </div>
                <div className="div-80">
                  <div className="div-81">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1027acc2ff2d5a78a029de9c79952e92216038ff53f584c469c00a169b63ee8e?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                      className="img-2"
                    />
                    <div className="div-82">My Task</div>
                  </div>
                  <div className="div-83">Add ETR</div>
                  <div className="div-84">Edit</div>
                </div>
              </div>
              <div className="div-85">
                <div className="div-86">
                  <div className="div-87">
                    <div className="div-88">
                      <div className="div-89">NMC Ticket Details</div>
                      <div className="div-90">
                        <div className="div-91">
                          <div className="column-3">
                            <div className="div-92">
                              <div className="div-93">Work Order Id : </div>
                              <div className="div-94">Event Start Date: </div>
                              <div className="div-95">Status: </div>
                              <div className="div-96">Priority: </div>
                              <div className="div-97">Location : </div>
                              <div className="div-98">Issue Description : </div>
                            </div>
                          </div>
                          <div className="column-4">
                            <div className="div-99">
                              <div className="div-100">WO00900004334</div>
                              <div className="div-101">
                                2022-07-12T17:16:14.000+0000
                              </div>
                              <div className="div-102">In Progress</div>
                              <div className="div-103">Critical</div>
                              <div className="div-104">
                                SOUTH EAST - CAROLINA/TENNESSEE COLUMBIA_MTX
                              </div>
                              <div className="div-105">Generator Failure</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="div-106">
                        <div className="div-107">More Details</div>
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/768bb38daf17ce6b3559c2217e8e7de83640a93f4c34e8ceeec1ad9dc0e37d36?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                          className="img-3"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="div-108">
                    <div className="div-109">
                      <div className="div-110">Work logs</div>
                      <div className="div-111">
                        <Base className="base-4" />
                      </div>
                    </div>
                    <div className="div-112">
                      <div className="div-113">
                        <div className="div-114">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1027acc2ff2d5a78a029de9c79952e92216038ff53f584c469c00a169b63ee8e?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                            className="img-4"
                          />
                          <div className="div-115">Add Worklog</div>
                        </div>
                      </div>
                      <div className="div-116">
                        <div className="div-117">
                          <Base className="base-5" />
                          <div className="div-118">Templates</div>
                        </div>
                        <div className="div-119">
                          <Base className="base-6" />
                          <div className="div-120">Filter</div>
                        </div>
                        <div className="div-121">
                          <Base className="base-7" />
                          <div className="div-122">Hide Columns</div>
                        </div>
                        <div className="div-123">
                          <Base className="base-8" />
                          <div className="div-124">Density</div>
                        </div>
                        <div className="div-125">
                          <Base className="base-9" />
                          <div className="div-126">Export</div>
                        </div>
                      </div>
                    </div>
                    <div className="div-127">
                      <div className="div-128">
                        <div className="column-5">
                          <div className="div-129">
                            <Base className="base-10">Description</Base>
                            <div className="div-130">
                              <div className="div-131">
                                <Base className="base-11" />
                                <div className="div-132">
                                  Network details were changed by user kayro
                                  <br />
                                  <br />
                                  FROM
                                  <br />
                                  ENVIRONMENTALS
                                  <br />
                                  BTS - Cell
                                  <br />
                                  <br />
                                  Generator Failure
                                  <br />
                                  TO:
                                  <br />
                                  ENVIRONMENTALS
                                  <br />
                                  Cell
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column-6">
                          <div className="div-133">
                            <Base className="base-12">Date & Time</Base>
                            <div className="div-134">
                              <div className="div-135">01/17/2024 03:50 PM</div>
                            </div>
                          </div>
                        </div>
                        <div className="column-7">
                          <div className="div-136">
                            <Base className="base-13">Created By</Base>
                            <div className="div-137">
                              <div className="div-138">
                                username@verizon.com
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .div {
          border-radius: 4px;
          background-color: #f6f6f6;
          padding: 11px 12px;
        }
        .div-2 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-2 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 24%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .div-3 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          letter-spacing: 0.5px;
        }
        @media (max-width: 991px) {
          .div-3 {
            margin-top: 23px;
          }
        }
        .div-4 {
          justify-content: center;
          border-radius: 4px;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          font-size: 14px;
        }
        .div-5 {
          display: flex;
          flex-direction: column;
        }
        .div-6 {
          justify-content: space-between;
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-bottom-width: 1px;
          display: flex;
          gap: 20px;
          font-size: 16px;
          color: #000;
          font-weight: 700;
          padding: 4px 12px;
        }
        .div-7 {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .div-8 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          justify-content: center;
        }
        .base {
          align-self: start;
          width: 18px;
          height: 18px;
        }
        .div-9 {
          display: flex;
          flex-direction: column;
          font-weight: 400;
          line-height: 143%;
        }
        .div-10 {
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-top-width: 1px;
          background-color: #f4f4f4;
          display: flex;
          gap: 20px;
          color: #000;
          padding: 4px 80px 4px 22px;
        }
        @media (max-width: 991px) {
          .div-10 {
            padding: 0 20px;
          }
        }
        .base-2 {
          width: 12px;
          height: 12px;
          margin: auto 0;
        }
        .div-11 {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .div-12 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          justify-content: center;
        }
        .div-13 {
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-top-width: 1px;
          background-color: #d9d9d9;
          display: flex;
          gap: 12px;
          font-size: 12px;
          color: #000;
          white-space: nowrap;
          line-height: 167%;
          padding: 4px 72px 4px 63px;
        }
        @media (max-width: 991px) {
          .div-13 {
            white-space: initial;
            padding: 0 20px;
          }
        }
        .img {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 12px;
          margin: auto 0;
        }
        .div-14 {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-14 {
            white-space: initial;
          }
        }
        .div-15 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-15 {
            white-space: initial;
          }
        }
        .div-16 {
          align-items: start;
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-top-width: 1px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 4px 21px;
        }
        @media (max-width: 991px) {
          .div-16 {
            padding: 0 20px;
          }
        }
        .div-17 {
          display: flex;
          width: 142px;
          max-width: 100%;
          flex-direction: column;
        }
        .div-18 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          text-decoration-line: underline;
          color: #000;
          white-space: nowrap;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-18 {
            white-space: initial;
          }
        }
        .div-19 {
          display: flex;
          gap: 4px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-19 {
            white-space: initial;
          }
        }
        .div-20 {
          color: #000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-21 {
          color: #cba900;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-22 {
          color: #000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-23 {
          align-items: start;
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-top-width: 1px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 4px 21px;
        }
        @media (max-width: 991px) {
          .div-23 {
            padding: 0 20px;
          }
        }
        .div-24 {
          display: flex;
          width: 142px;
          max-width: 100%;
          flex-direction: column;
        }
        .div-25 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          text-decoration-line: underline;
          color: #000;
          white-space: nowrap;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-25 {
            white-space: initial;
          }
        }
        .div-26 {
          display: flex;
          gap: 4px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-26 {
            white-space: initial;
          }
        }
        .div-27 {
          color: #000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-28 {
          color: #008331;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-29 {
          color: #000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-30 {
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-top-width: 1px;
          background-color: #d9d9d9;
          display: flex;
          gap: 12px;
          font-size: 12px;
          color: #000;
          white-space: nowrap;
          line-height: 167%;
          padding: 4px 72px 4px 63px;
        }
        @media (max-width: 991px) {
          .div-30 {
            white-space: initial;
            padding: 0 20px;
          }
        }
        .div-31 {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-31 {
            white-space: initial;
          }
        }
        .div-32 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-32 {
            white-space: initial;
          }
        }
        .div-33 {
          display: flex;
          flex-direction: column;
          line-height: 143%;
        }
        .div-34 {
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-top-width: 1px;
          background-color: #f4f4f4;
          display: flex;
          gap: 20px;
          color: #000;
          font-weight: 400;
          padding: 4px 80px 4px 22px;
        }
        @media (max-width: 991px) {
          .div-34 {
            padding: 0 20px;
          }
        }
        .base-3 {
          width: 12px;
          height: 12px;
          margin: auto 0;
        }
        .div-35 {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .div-36 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          justify-content: center;
        }
        .div-37 {
          align-items: start;
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-top-width: 1px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 4px 21px;
        }
        @media (max-width: 991px) {
          .div-37 {
            padding: 0 20px;
          }
        }
        .div-38 {
          display: flex;
          width: 145px;
          max-width: 100%;
          flex-direction: column;
        }
        .div-39 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          text-decoration-line: underline;
          color: #000;
          font-weight: 700;
          white-space: nowrap;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-39 {
            white-space: initial;
          }
        }
        .div-40 {
          display: flex;
          gap: 4px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-40 {
            white-space: initial;
          }
        }
        .div-41 {
          color: #0089ec;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          font-weight: 700;
        }
        .div-42 {
          color: #ed7000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          font-weight: 400;
        }
        .div-43 {
          color: #000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          font-weight: 400;
        }
        .div-44 {
          align-items: start;
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-top-width: 1px;
          background-color: #aad8f9;
          display: flex;
          flex-direction: column;
          font-weight: 400;
          justify-content: center;
          padding: 4px 21px;
        }
        @media (max-width: 991px) {
          .div-44 {
            padding: 0 20px;
          }
        }
        .div-45 {
          display: flex;
          width: 142px;
          max-width: 100%;
          flex-direction: column;
        }
        .div-46 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          text-decoration-line: underline;
          color: #000;
          white-space: nowrap;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-46 {
            white-space: initial;
          }
        }
        .div-47 {
          display: flex;
          gap: 4px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-47 {
            margin-right: 5px;
            white-space: initial;
          }
        }
        .div-48 {
          color: #000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-49 {
          color: #ed7000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-50 {
          color: #000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-51 {
          align-items: start;
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-top-width: 1px;
          display: flex;
          flex-direction: column;
          font-weight: 700;
          justify-content: center;
          padding: 4px 21px;
        }
        @media (max-width: 991px) {
          .div-51 {
            padding: 0 20px;
          }
        }
        .div-52 {
          display: flex;
          width: 156px;
          max-width: 100%;
          flex-direction: column;
        }
        .div-53 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          text-decoration-line: underline;
          color: #000;
          white-space: nowrap;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-53 {
            white-space: initial;
          }
        }
        .div-54 {
          display: flex;
          gap: 4px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-54 {
            white-space: initial;
          }
        }
        .div-55 {
          color: #0d9aff;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-56 {
          color: #dab600;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-57 {
          color: #000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          font-weight: 400;
        }
        .div-58 {
          align-items: start;
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-top-width: 1px;
          display: flex;
          flex-direction: column;
          font-weight: 400;
          justify-content: center;
          padding: 4px 21px;
        }
        @media (max-width: 991px) {
          .div-58 {
            padding: 0 20px;
          }
        }
        .div-59 {
          display: flex;
          width: 142px;
          max-width: 100%;
          flex-direction: column;
        }
        .div-60 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          text-decoration-line: underline;
          color: #000;
          white-space: nowrap;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-60 {
            white-space: initial;
          }
        }
        .div-61 {
          display: flex;
          gap: 4px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-61 {
            white-space: initial;
          }
        }
        .div-62 {
          color: #000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-63 {
          color: #008331;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-64 {
          color: #000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-65 {
          display: flex;
          flex-direction: column;
          color: #000;
          font-weight: 400;
          line-height: 143%;
          justify-content: center;
        }
        .div-66 {
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-top-width: 1px;
          background-color: #f4f4f4;
          display: flex;
          gap: 20px;
          padding: 4px 80px 4px 22px;
        }
        @media (max-width: 991px) {
          .div-66 {
            padding: 0 20px;
          }
        }
        .div-67 {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .div-68 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          justify-content: center;
        }
        .div-69 {
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-top-width: 1px;
          background-color: #f4f4f4;
          display: flex;
          gap: 20px;
          color: #000;
          font-weight: 400;
          line-height: 143%;
          padding: 4px 80px 4px 22px;
        }
        @media (max-width: 991px) {
          .div-69 {
            padding: 0 20px;
          }
        }
        .div-70 {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .div-71 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          justify-content: center;
        }
        .div-72 {
          border-color: rgba(183, 183, 183, 1);
          border-style: solid;
          border-top-width: 1px;
          background-color: #f4f4f4;
          display: flex;
          gap: 20px;
          color: #000;
          font-weight: 400;
          line-height: 143%;
          padding: 4px 80px 4px 22px;
        }
        @media (max-width: 991px) {
          .div-72 {
            padding: 0 20px;
          }
        }
        .div-73 {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .div-74 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          justify-content: center;
        }
        .div-75 {
          font-feature-settings: "clig" off, "liga" off;
          border-radius: 22px;
          border-color: rgba(0, 0, 0, 1);
          border-style: solid;
          border-width: 1px;
          background-color: var(--whitelffffffd-000000, #fff);
          margin-top: 8px;
          color: var(--blackl-000000-dffffff, #000);
          white-space: nowrap;
          justify-content: center;
          padding: 12px 24px;
          font: 700 16px Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-75 {
            white-space: initial;
            padding: 0 20px;
          }
        }
        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 76%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .div-76 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-76 {
            max-width: 100%;
            margin-top: 23px;
          }
        }
        .div-77 {
          justify-content: center;
          border-radius: 4px;
          background-color: #fff;
          display: flex;
          width: 100%;
          gap: 20px;
          padding: 4px 10px;
        }
        @media (max-width: 991px) {
          .div-77 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-78 {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          color: var(--gray-20-l-333333-dffffff, #333);
          font-weight: 400;
          white-space: nowrap;
          line-height: 129%;
          justify-content: center;
          margin: auto 0;
          padding: 1px 0;
        }
        @media (max-width: 991px) {
          .div-78 {
            white-space: initial;
          }
        }
        .div-79 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-79 {
            white-space: initial;
          }
        }
        .div-80 {
          display: flex;
          gap: 12px;
          font-size: 12px;
          color: var(--blackl-000000-dffffff, #000);
          font-weight: 700;
          line-height: 150%;
        }
        .div-81 {
          border-radius: 22px;
          border-color: rgba(0, 0, 0, 1);
          border-style: solid;
          border-width: 1px;
          background-color: var(--whitelffffffd-000000, #fff);
          display: flex;
          gap: 8px;
          padding: 8px 16px;
        }
        .img-2 {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 16px;
          align-self: start;
        }
        .div-82 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-83 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          border-radius: 22px;
          border-color: rgba(0, 0, 0, 1);
          border-style: solid;
          border-width: 1px;
          background-color: var(--whitelffffffd-000000, #fff);
          justify-content: center;
          padding: 8px 16px;
        }
        .div-84 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          border-radius: 22px;
          background-color: var(--blackl-000000-dffffff, #000);
          color: var(--whitelffffffd-000000, #fff);
          white-space: nowrap;
          justify-content: center;
          padding: 8px 16px;
        }
        @media (max-width: 991px) {
          .div-84 {
            white-space: initial;
          }
        }
        .div-85 {
          justify-content: center;
          border-radius: 4px;
          background-color: #fff;
          display: flex;
          margin-top: 12px;
          flex-direction: column;
          padding: 7px 8px 0;
        }
        @media (max-width: 991px) {
          .div-85 {
            max-width: 100%;
          }
        }
        .div-86 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-86 {
            max-width: 100%;
          }
        }
        .div-87 {
          border-radius: 4px;
          background-color: rgba(246, 246, 246, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 20px 16px;
        }
        @media (max-width: 991px) {
          .div-87 {
            max-width: 100%;
            padding-right: 20px;
          }
        }
        .div-88 {
          align-items: start;
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-88 {
            max-width: 100%;
          }
        }
        .div-89 {
          font-feature-settings: "clig" off, "liga" off;
          padding-bottom: 9px;
          color: #000;
          letter-spacing: 1px;
          font: 700 14px/143% Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-90 {
          align-self: stretch;
          margin-top: 9px;
        }
        @media (max-width: 991px) {
          .div-90 {
            max-width: 100%;
            padding-right: 20px;
          }
        }
        .div-91 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-91 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 15%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .div-92 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          font-size: 14px;
          color: #000;
          font-weight: 400;
          letter-spacing: 0.5px;
          line-height: 143%;
        }
        @media (max-width: 991px) {
          .div-92 {
            margin-top: 4px;
          }
        }
        .div-93 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          justify-content: center;
        }
        .div-94 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          margin-top: 9px;
          justify-content: center;
        }
        .div-95 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          margin-top: 9px;
          justify-content: center;
        }
        .div-96 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          margin-top: 9px;
          justify-content: center;
        }
        .div-97 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          margin-top: 9px;
          justify-content: center;
        }
        .div-98 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          margin-top: 9px;
          justify-content: center;
        }
        .column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 85%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
          }
        }
        .div-99 {
          align-items: start;
          display: flex;
          flex-grow: 1;
          padding-right: 35px;
          flex-direction: column;
          font-size: 14px;
          color: #000;
          font-weight: 400;
          letter-spacing: 1px;
          line-height: 143%;
        }
        @media (max-width: 991px) {
          .div-99 {
            max-width: 100%;
            margin-top: 4px;
          }
        }
        .div-100 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          white-space: nowrap;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-100 {
            white-space: initial;
          }
        }
        .div-101 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          margin-top: 9px;
          white-space: nowrap;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-101 {
            white-space: initial;
          }
        }
        .div-102 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          margin-top: 9px;
          justify-content: center;
        }
        .div-103 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          margin-top: 9px;
          white-space: nowrap;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-103 {
            white-space: initial;
          }
        }
        .div-104 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          align-self: stretch;
          margin-top: 9px;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-104 {
            max-width: 100%;
          }
        }
        .div-105 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          margin-top: 9px;
          justify-content: center;
        }
        .div-106 {
          justify-content: end;
          display: flex;
          margin-top: 9px;
          gap: 11px;
          font-size: 14px;
          color: #0089ec;
          font-weight: 400;
          letter-spacing: 1px;
          line-height: 143%;
        }
        .div-107 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          text-decoration-line: underline;
          justify-content: center;
        }
        .img-3 {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 14px;
          margin: auto 0;
        }
        .div-108 {
          background-color: #fff;
          display: flex;
          margin-top: 12px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-108 {
            max-width: 100%;
          }
        }
        .div-109 {
          justify-content: space-between;
          display: flex;
          gap: 8px;
        }
        @media (max-width: 991px) {
          .div-109 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-110 {
          font-feature-settings: "clig" off, "liga" off;
          color: var(--blackl-000000-dffffff, #000);
          justify-content: center;
          margin: auto 0;
          font: 700 14px/28px Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-111 {
          justify-content: center;
          display: flex;
          flex-direction: column;
        }
        .base-4 {
          justify-content: center;
        }
        .div-112 {
          justify-content: space-between;
          background-color: var(--gray-95-lf-6-f-6-f-6-d-1-b-1-d-1-f, #f6f6f6);
          display: flex;
          margin-top: 8px;
          width: 100%;
          gap: 8px;
          font-size: 14px;
          color: var(--blackl-000000-dffffff, #000);
          font-weight: 400;
          letter-spacing: 0.5px;
          line-height: 143%;
          padding: 8px 12px;
        }
        @media (max-width: 991px) {
          .div-112 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-113 {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .div-114 {
          display: flex;
          gap: 4px;
        }
        .img-4 {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 16px;
          margin: auto 0;
        }
        .div-115 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-116 {
          display: flex;
          gap: 12px;
        }
        @media (max-width: 991px) {
          .div-116 {
            flex-wrap: wrap;
          }
        }
        .div-117 {
          display: flex;
          gap: 4px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-117 {
            white-space: initial;
          }
        }
        .base-5 {
          width: 16px;
          height: 16px;
          margin: auto 0;
        }
        .div-118 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-119 {
          display: flex;
          gap: 4px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-119 {
            white-space: initial;
          }
        }
        .base-6 {
          width: 16px;
          height: 16px;
          margin: auto 0;
        }
        .div-120 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-121 {
          display: flex;
          gap: 4px;
        }
        .base-7 {
          width: 16px;
          height: 16px;
          margin: auto 0;
        }
        .div-122 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-123 {
          display: flex;
          gap: 4px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-123 {
            white-space: initial;
          }
        }
        .base-8 {
          width: 16px;
          height: 16px;
          margin: auto 0;
        }
        .div-124 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-125 {
          display: flex;
          gap: 4px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-125 {
            white-space: initial;
          }
        }
        .base-9 {
          width: 16px;
          height: 16px;
          margin: auto 0;
        }
        .div-126 {
          font-feature-settings: "clig" off, "liga" off;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-127 {
          margin-top: 8px;
        }
        @media (max-width: 991px) {
          .div-127 {
            max-width: 100%;
          }
        }
        .div-128 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-128 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-5 {
            width: 100%;
          }
        }
        .div-129 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-129 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .base-10 {
          justify-content: center;
        }
        @media (max-width: 991px) {
          .base-10 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .div-130 {
          border-color: rgba(216, 218, 218, 1);
          border-style: solid;
          border-bottom-width: 1px;
          background-color: var(--whitelffffffd-000000, #fff);
          display: flex;
          flex-direction: column;
          font-size: 14px;
          color: var(--blackl-000000-dffffff, #000);
          font-weight: 400;
          letter-spacing: 0.5px;
          line-height: 20px;
          padding: 12px 12px 0;
        }
        @media (max-width: 991px) {
          .div-130 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .div-131 {
          display: flex;
          gap: 8px;
        }
        @media (max-width: 991px) {
          .div-131 {
            flex-wrap: wrap;
            white-space: initial;
          }
        }
        .base-11 {
          align-self: start;
          width: 16px;
          height: 16px;
        }
        .div-132 {
          font-feature-settings: "clig" off, "liga" off;
          text-overflow: ellipsis;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          flex: 1;
        }
        @media (max-width: 991px) {
          .div-132 {
            white-space: initial;
          }
        }
        .column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 19%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-6 {
            width: 100%;
          }
        }
        .div-133 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        .base-12 {
          justify-content: center;
        }
        .div-134 {
          border-color: rgba(216, 218, 218, 1);
          border-style: solid;
          border-bottom-width: 1px;
          background-color: var(--whitelffffffd-000000, #fff);
          display: flex;
          flex-direction: column;
          font-size: 14px;
          color: var(--blackl-000000-dffffff, #000);
          font-weight: 400;
          letter-spacing: 0.5px;
          line-height: 143%;
          padding: 12px 12px 9px;
        }
        .div-135 {
          max-width: 200px;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          font-feature-settings: "clig" off, "liga" off;
          text-overflow: ellipsis;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          justify-content: center;
          margin-top: 42px;
        }
        @media (max-width: 991px) {
          .div-135 {
            margin-top: 40px;
          }
        }
        .column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 29%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-7 {
            width: 100%;
          }
        }
        .div-136 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        .base-13 {
          justify-content: center;
        }
        .div-137 {
          align-items: start;
          border-color: rgba(216, 218, 218, 1);
          border-style: solid;
          border-bottom-width: 1px;
          background-color: var(--whitelffffffd-000000, #fff);
          display: flex;
          flex-direction: column;
          font-size: 14px;
          color: var(--blackl-000000-dffffff, #000);
          font-weight: 400;
          white-space: nowrap;
          letter-spacing: 0.5px;
          line-height: 143%;
          padding: 12px 12px 9px;
        }
        @media (max-width: 991px) {
          .div-137 {
            padding-right: 20px;
            white-space: initial;
          }
        }
        .div-138 {
          max-width: 200px;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          font-feature-settings: "clig" off, "liga" off;
          text-overflow: ellipsis;
          font-family: Verizon NHG eDS, -apple-system, Roboto, Helvetica,
            sans-serif;
          justify-content: center;
          margin-top: 42px;
        }
        @media (max-width: 991px) {
          .div-138 {
            margin-top: 40px;
            white-space: initial;
          }
        }
      `}</style>
    </>
  );
}


