import * as React from "react";
import styled from "styled-components";

export default function Sample() {
  return (
    <Div>
      <Div2>
        <Div3>
          <Div4>RRC Setup</Div4>
          <Img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e8ecc6abf8d0d380b5acf3ba8150061788ac7c36da1d80a9e84e3798cfbc86af?apiKey=8a6f48dca770437b9a63cd96b0d34ad1&"
          />
          <Div5>
            <Div6>12AM</Div6>
            <Div7>1AM</Div7>
            <Div8>2AM</Div8>
          </Div5>
          <Div9>83</Div9>
          <Div10>Avg Drops&gt; .5% : 67</Div10>
        </Div3>
      </Div2>
      <Div11>
        <Div12>
          <Div13>SIP DC%</Div13>
          <Img2
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bfab7693e6058c16412cc6694d58c176180f10578f11dd6eb365db35c0c926c?apiKey=8a6f48dca770437b9a63cd96b0d34ad1&"
          />
          <Div14>
            <Div15>12AM</Div15>
            <Div16>1AM</Div16>
            <Div17>2AM</Div17>
          </Div14>
          <Div18>94</Div18>
          <Div19>Avg Drops&gt; .5% : 89</Div19>
        </Div12>
      </Div11>
      <Div20>
        <Div21>
          <Div22>Bearer Drop%</Div22>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b9856b93e9a0aa2ca560c0e7a70207cb4409d56945006c3efd9fd9adf1a46d09?apiKey=8a6f48dca770437b9a63cd96b0d34ad1&"
          />
          <Div23>
            <Div24>12AM</Div24>
            <Div25>1AM</Div25>
            <Div26>2AM</Div26>
          </Div23>
          <Div27>88</Div27>
          <Div28>Avg Drops&gt; .5% : 43 </Div28>
        </Div21>
      </Div20>
      <Div29>
        <Div30>
          <Div31>UPTP Mbps</Div31>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/028b6e394e265715c2e97de59b9824de6171f3c5e30e2155e7ce3ee2b8e12f88?apiKey=8a6f48dca770437b9a63cd96b0d34ad1&"
          />
          <Div32>
            <Div33>12AM</Div33>
            <Div34>1AM</Div34>
            <Div35>2AM</Div35>
          </Div32>
          <Div36>81</Div36>
          <Div37>Avg Drops&gt; .5% : 45</Div37>
        </Div30>
      </Div29>
      <Div38>
        <Div39>
          <Div40>ENDC Support</Div40>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7b3e1b72b4e2f1275f3dd85206cc512f00adb0127c198aed3c74a5ad609abcd6?apiKey=8a6f48dca770437b9a63cd96b0d34ad1&"
          />
          <Div41>
            <Div42>12AM</Div42>
            <Div43>1AM</Div43>
            <Div44>2AM</Div44>
          </Div41>
          <Div45>92</Div45>
          <Div46>Avg Drops&gt; .5% : 20</Div46>
        </Div39>
      </Div38>
      <Img6
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/31e510c56282322934ee89aaeaa57e69488482c03f4400ec257a8f23871aad1c?apiKey=8a6f48dca770437b9a63cd96b0d34ad1&"
      />
    </Div>
  );
}

const Div = styled.div`
  display: flex;
  margin-top: 23px;
  gap: 11px;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div3 = styled.div`
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px 12px 8px;
`;

const Div4 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
`;

const Img = styled.img`
  aspect-ratio: 4.55;
  object-fit: auto;
  object-position: center;
  width: 133px;
  margin-top: 31px;
`;

const Div5 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 8px;
  gap: 20px;
  font-size: 10px;
  color: #6f7171;
  font-weight: 400;
  white-space: nowrap;
  justify-content: space-between;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div6 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div7 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div8 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div9 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 17px;
`;

const Div10 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  font-weight: 400;
  margin-top: 29px;
`;

const Div11 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div12 = styled.div`
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px 12px 8px;
`;

const Div13 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
`;

const Img2 = styled.img`
  aspect-ratio: 4.55;
  object-fit: auto;
  object-position: center;
  width: 133px;
  margin-top: 33px;
`;

const Div14 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 8px;
  gap: 20px;
  font-size: 10px;
  color: #6f7171;
  font-weight: 400;
  white-space: nowrap;
  justify-content: space-between;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div15 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div16 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div17 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div18 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 17px;
`;

const Div19 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  font-weight: 400;
  margin-top: 29px;
`;

const Div20 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div21 = styled.div`
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px 12px 8px;
`;

const Div22 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div23 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 8px;
  gap: 20px;
  font-size: 10px;
  color: #6f7171;
  font-weight: 400;
  white-space: nowrap;
  justify-content: space-between;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div24 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div25 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div26 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div27 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 17px;
`;

const Div28 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  font-weight: 400;
  margin-top: 29px;
`;

const Div29 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div30 = styled.div`
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px 12px 8px;
`;

const Div31 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div32 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 8px;
  gap: 20px;
  font-size: 10px;
  color: #6f7171;
  font-weight: 400;
  white-space: nowrap;
  justify-content: space-between;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div33 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div34 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div35 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div36 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 17px;
`;

const Div37 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  font-weight: 400;
  margin-top: 29px;
`;

const Div38 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div39 = styled.div`
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px 12px 8px;
`;

const Div40 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div41 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 8px;
  gap: 20px;
  font-size: 10px;
  color: #6f7171;
  font-weight: 400;
  white-space: nowrap;
  justify-content: space-between;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div42 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div43 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div44 = styled.div`
  font-family: Verizon NHG eDS, sans-serif;
`;

const Div45 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  margin-top: 17px;
`;

const Div46 = styled.div`
  text-align: center;
  font-family: Verizon NHG eDS, sans-serif;
  font-weight: 400;
  margin-top: 29px;
`;

const Img6 = styled.img`
  aspect-ratio: 1.08;
  object-fit: auto;
  object-position: center;
  width: 116px;
  align-self: start;
  margin-top: 15px;
  max-width: 100%;
`;


